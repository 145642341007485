import React, { Component } from 'react';
import {api} from '../../api';
import Header from '../../general/navigation/Header';
import Loading from '../../general/components/Loading';

class ManageCountries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: []
    };
  }

  componentDidMount() {
    api.get(`/countries`)
      .then(response => {
        if (response) {
          this.setState({countries: response.data})
        }
      });
  }

  addCountry = () =>{
    window.location.assign('/#/countries/create');
  }

  editCountry = (id) =>{
    window.location.assign('/#/countries/' + id + '/edit');
  }

  render() {
    return (
      <React.Fragment>
        {this.state.countries ?
        <React.Fragment>
          <Header showClientNavigation ={false} showImmigrationNavigation={false} displayName={null} link={null}/>
          <div className="container manage-countries-container p-5 d-flex justify-content-center align-items-center">
          <button onClick ={this.addCountry} type="button" className="btn btn-secondary m-4">Add New Country</button>
          <table className="manage-countries-table">
            <thead>
              <tr>
                <td className="text-secondary font-weight-bold">Name</td>
                <td className="text-secondary font-weight-bold">Code</td>
                {/* <td className="text-secondary font-weight-bold">Points Based</td> */}
                <td></td>
              </tr>
            </thead>
            <tbody>
              {this.state.countries.map((country, index) =>
                <tr key={index}>
                  <td className="text-secondary font-weight-light">{country.name}</td>
                  <td className="text-secondary font-weight-light">{country.code}</td>
                  {/* <td className={"d-flex justify-content-center text-" + (country.isPointsBased ? "success" : "danger")}><h4 className="font-weight-light">{country.isPointsBased ? "✓" : "x"}</h4></td> */}
                  <td><button onClick={() => this.editCountry(country.id)} type="button" className="btn btn-primary">Edit</button></td>
                </tr>
              )}
            </tbody>
          </table>
          </div>
        </React.Fragment>
        :
        <Loading />
        }
      </React.Fragment>
    )
  }
}

export default ManageCountries;