import { api } from '../api';
// import { appId } from '../azure';

const fetchCompanies = async () => {
  const companiesResponse = await api.get(`/companies`);
  return companiesResponse ? companiesResponse.data : [];
};

const fetchCompany = async (companyId) => {
  const companyResponse = await api.get(`/companies/${companyId}`);
  return companyResponse ? companyResponse.data : null;
};

const createCompany = async (companyData) => {
  return await api.post(`/companies`, companyData);
};

const fetchManuallyAssessedPermits = async (companyId) => {
  const manualPermitResponse = await api.get(`/companies/${companyId}/manually-assessed-permits`);
  return manualPermitResponse ? manualPermitResponse.data : [];
};

const createManuallyAssessedPermits = async (companyId, workPermits) => {
  return await api.post(`/companies/${companyId}/add-manually-assessed-permits`, workPermits);
};

const removeManuallyAssessedPermits = async (companyId, workPermits) => {
  return await api.delete(`/companies/${companyId}/remove-permits-from-manual-assess`, workPermits);
};

const fetchCompanyAnnouncements = async (companyId) => {
  const announcementsResponse = await api.get(`/announcements`, {headers: {client: companyId}});
  return announcementsResponse ? announcementsResponse.data : [];
};

const saveAnnouncement = async (announcementData) => {
 return await api.post(`/announcements`, announcementData);
};

const updateAnnouncement = async (announcementId, announcementData) => {
  return await api.patch(`/announcements/${announcementId}`, announcementData);
};

const deleteAnnouncement = async (announcementId) => {
 return await api.delete(`/announcements/${announcementId}`);
};

const fetchRequirementBlacklist = async (companyId) => {
  const requirementBlacklistResponse = await api.get(`/companies/${companyId}/blacklisted-requirements`);
  return requirementBlacklistResponse ? requirementBlacklistResponse.data : [];
};

const blacklistRequirement = async (companyId, requirementId) => {
  const requirement = {
    id: null,
    company: companyId,
    requirement: requirementId,
  }
  return await api.post(`/companies/add-blacklisted-requirement`, requirement);
};

const unblacklistRequirement = async (companyId, requirementId) => {
  const requirement = {
    company: companyId,
    requirement: requirementId,
  }
  return await api.delete(`/companies/unblacklist-requirement`, {data: requirement});
};

const fetchJobPositions = async (companyId) => {
  const jobPositionsResponse = await api.get(`/companies/${companyId}/job-positions`);
  return jobPositionsResponse ? jobPositionsResponse.data : [];
};

export {
  fetchCompany,
  fetchCompanies,
  createCompany,
  fetchManuallyAssessedPermits,
  createManuallyAssessedPermits,
  removeManuallyAssessedPermits,
  fetchCompanyAnnouncements,
  saveAnnouncement,
  updateAnnouncement,
  deleteAnnouncement,
  fetchRequirementBlacklist,
  blacklistRequirement,
  unblacklistRequirement,
  fetchJobPositions,
}
