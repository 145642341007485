import React, { Component } from 'react';
import { UserAgentApplication } from 'msal';
import Header from '../../general/navigation/Header';
import azure from '../../azure';
import { loginUser } from '../../services/UserService';

export default class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      user: {},
      error: null,
    };
  }

  componentDidMount() {
    this.initializeSSO();
  }


  async initializeSSO() {
    this.msalInstance = new UserAgentApplication({
      auth: {
        clientId: azure.appId,
        authority: azure.ssoURL,
        redirectUri: azure.redirectUri,
      }
    });
  }

  async login() {
    if (process.env.REACT_APP_SSO_ENABLED !== 'false') {
      this.msalInstance.loginPopup({scopes: azure.scopes})
        .then(async response => {
          loginUser(response).then(() => {
            this.setState({
              isAuthenticated: true,
              user: response.account,
              error: null
            }, () => window.location.replace('/#/'));
          });
        })
        .catch(error => {
          this.setState({
            isAuthenticated: false,
            user: {},
            error,
          });
        })
    } else {
      const user = {
        account: {
          userName: 'userTest'
        }
      };
      loginUser(user).then(() => {
        this.setState({
          isAuthenticated: true,
          user: user.account,
          error: null
        }, () => window.location.replace('/#/'));
      });      
    }
  }

  render() {
    return (
      <React.Fragment>
        <Header showClientNavigation ={false} showImmigrationNavigation={false} displayName={null} link={null} />
        <div className="d-flex justify-content-center mt-10">
          {
            !this.state.isAuthenticated && <button className="btn btn-primary mt-5" onClick={() => this.login()}>Sign In</button>
          }
        </div>
      </React.Fragment>
    )
  }
}
