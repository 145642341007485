import React, { Fragment, useEffect, useState } from "react";
import { api } from "../../api";
import Header from "../../general/navigation/Header";
import Loading from "../../general/components/Loading.js";
import VisaCitizenshipProcessingTimeRow from "./VisaCitizenshipProcessingTimeRow";
import Select from "react-select";

const VisaCitizenshipProcessingTime = () => {
  // Setup state variables
  const [citizenshipProcessingTimeMap, setCitizenshipProcessingTimeMap] =
    useState(null);
  const [country, setCountry] = useState(null);
  const [countries, setCountries] = useState([]);
  const [filteredCountry, setFilteredCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [countryOptions, setCountryOptions] = useState(null);
  const [governmentProcessingData, setGovernmentProcessingData] = useState([]);

  // Retrieve the country id
  const countryId = localStorage.getItem("countryId");

  useEffect(() => {
    if (!countryId) {
      window.location.replace("/#/");
    } else {
      setupPageData();
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countries, country, countryId]);

  const setupPageData = async () => {
    if (!country || !countries) {
      await fetchCountries();
      await fetchCountryData();
    } else {
      await createCitizenshipProcessingTimesMap();
      processingTimesCountryList();
    }
  };

  const fetchCountryData = async () => {
    const countryResponse = await api.get(`/countries`);
    let country = countryResponse.data.filter(
      (country) => country.id.toString() === countryId.toString()
    )[0];

    setCountry(country);
  };

  const fetchCountries = async () => {
    const countryResponse = await api.get(`/countries`);
    const countries = [];

    const notDisclosedId = 0;
    const notDisclosedResponse = await api.get(`/countries/${notDisclosedId}`);
    if (notDisclosedResponse.data) {
      countries.push(notDisclosedResponse.data);
    }

    for (const country of countryResponse.data) {
      if (country.id.toString() !== countryId.toString()) {
        countries.push(country);
      }
    }

    setCountries(countries);
  };

  const createCitizenshipProcessingTimesMap = async () => {
    const visaResponse = await api.get(`/visas`);
    const relevantVisas = visaResponse.data.filter(
      (visa) => visa.destinationRegion.id === country.regions[0].id
    );
    let citizenshipProcessingTimeTemporaryMap = new Map();

    for (const country of countries) {
      const countryRegionId = country.regions[0] ? country.regions[0].id : null;
      let visaObject = relevantVisas.filter(
        (visa) => visa.sourceRegion.id === countryRegionId
      )[0];
      citizenshipProcessingTimeTemporaryMap.set(countryRegionId, visaObject);
    }
    setCitizenshipProcessingTimeMap(citizenshipProcessingTimeTemporaryMap);
  };

  /**
   * Filter to show only the country which was selected, unless 'All' was selected, in which case, filter none of the countries.
   * @param {object} event on change in the country select dropdown.
   */
  const handleFilterUpdate = (event) => {
    if (event.value === "All") {
      setFilteredCountry(null);
    } else {
      setFilteredCountry(
        countries.filter((country) => country.id === event.value.id)[0]
      );
    }
  };

  const isPageReady = () => {
    return countries && country && citizenshipProcessingTimeMap;
  };

  /**
   * Sets the list of countries to appear as options in the dropdown.
   */
  const processingTimesCountryList = () => {
    const countryList = [];
    // Set both value and label of 'All' as a string, so that only the string needs to be compared.
    countryList.push({
      label: "All",
      value: "All",
    });

    for (const countryOption of countries) {
      countryList.push({
        label: countryOption.name,
        value: countryOption,
      });
    }

    setCountryOptions(countryList);
  };

  const saveMultiple = async (visaObject) => {
    await api
      .patch(`/visas/`, visaObject)
      .catch((error) => console.error(error));
    await api
      .post(`/visas/all/`, visaObject)
      .catch((error) => console.error(error));

    setupPageData();
    setGovernmentProcessingData([]);
  };

  return (
    <Fragment>
      <Header
        showClientNavigation={false}
        showImmigrationNavigation={true}
        displayName={country ? country.name : ""}
        link={"/select-country"}
      />
      {isLoading ? (
        <Loading />
      ) : (
        isPageReady() && (
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h3 className="gw__section-headings">
                  Citizenship Processing Times
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-12 d-flex justify-content-center mb-4 mt-2">
                <div className="col-6">
                  <label htmlFor="country-select" className="text-secondary">
                    SELECT COUNTRY
                  </label>
                  <Select
                    options={countryOptions}
                    onChange={(event) => handleFilterUpdate(event)}
                  />
                </div>
              </div>
            </div>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Citizenship</th>
                  <th scope="col">Visa Status</th>
                  <th scope="col">Processing Time (Weeks)</th>
                  <th scope="col">Premium Processing Time (Weeks)</th>
                  <th scope="col"></th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {filteredCountry ? (
                  <VisaCitizenshipProcessingTimeRow
                    country={filteredCountry}
                    destinationCountry={country}
                    setupPageData={setupPageData}
                    visa={citizenshipProcessingTimeMap.get(filteredCountry.id)}
                    setGovernmentProcessingData={setGovernmentProcessingData}
                  />
                ) : (
                  countries.map((sourceCountry, index) => {
                    return (
                      <VisaCitizenshipProcessingTimeRow
                        key={index}
                        country={sourceCountry}
                        destinationCountry={country}
                        setupPageData={setupPageData}
                        visa={citizenshipProcessingTimeMap.get(
                          sourceCountry.id
                        )}
                        setGovernmentProcessingData={
                          setGovernmentProcessingData
                        }
                      />
                    );
                  })
                )}
              </tbody>
            </table>
          </div>
        )
      )}
      <div>
        <button
          type="button"
          onClick={() => saveMultiple(governmentProcessingData)}
          className="btn btn-primary"
          style={{ position: "fixed", top: "200px", left: "0%" }}
        >
          Save All
        </button>
        {/* <button
            type="button"
            onClick={() =>
              deleteMultiple(visas)
            }
            className="btn btn-danger"
            style={{position:"fixed", top:'270px', left:'0%'}}
            >Delete All</button> */}
        {/* <SaveAllButton visaObject={governmentProcessingData} setupPageData={setupPageData}/> */}
      </div>
    </Fragment>
  );
};

export default VisaCitizenshipProcessingTime;
