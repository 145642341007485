import React, {Fragment, useEffect, useState} from 'react';
import {api} from '../../api';
import Header from '../../general/navigation/Header';
import Loading from '../../general/components/Loading';
import {fetchCountries} from "../../services/CountryService";
import Select from "react-select";

// todo: implement delete and update functionality with buttons

const ManageCompanyJobLevelMappings = ({match}) => {
	
	const [isLoading, setIsLoading] = useState(true);
	const [companyDisplayName, setCompanyDisplayName] = useState('');
	const [companyId, setCompanyId] = useState(null);
	const [countrySelector, showCountrySelector] = useState(true);
	const [companyJobLevels, setCompanyJobLevels] = useState([]);
	const [countryJobLevels, setCountryJobLevels] = useState([]);
	const [selectedValue, setSelectedValue] = useState(0);
	const [countryJobLevelMappings, setCountryJobLevelMappings] = useState([]);
	const [hasMappingChanged, setHasMappingChanged] = useState(false);
	// const [mappingResponse, setMappingResponse] = useState([]);
	const [deck] = useState([]);
	
	useEffect(() => {
		
		fetchCompany().then(function (companyResponse) {
			setCompanyDisplayName(companyResponse.data.name);
			setCompanyId(companyResponse.data.id);
		});
		setIsLoading(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		fetchJobLevelMappings().then((mappingResult) => {
			setCountryJobLevelMappings(mappingResult);
			setHasMappingChanged(false);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [countryJobLevels, hasMappingChanged])
	
	const createJobLevelMapping = async (countryJobLevelId, clientJobLevelId) => {
		await api.post('/job-levels/mappings/create', [countryJobLevelId, clientJobLevelId]);
		setHasMappingChanged(true);
		// window.location.reload();
	}
	
	// const updateJobLevelMapping = async (countryJobLevelId, clientJobLevelId) => {
	// 	await api.post('/job-levels/mappings/update', [countryJobLevelId, clientJobLevelId]);
	// }
	
	const deleteJobLevelMapping = async (countryJobLevelId, clientJobLevelId) => {
		await api.post('/job-levels/mappings/delete', [countryJobLevelId, clientJobLevelId]);
		setHasMappingChanged(true);
	}
	
	const fetchCompany = async () => {
		return await api.get(`/companies/${match.params.id}`);
	};
	
	const fetchCountryJobLevels = (event) => {
		return api.get('/job-levels/countries/' + parseInt(event.value.id));
	}

	const fetchJobLevelMappings = async () => {
		const tempJobLevelMapByCountryLevel = [];
		for (const countryJobLevel of countryJobLevels) {
			const jobLevelMappingResponse = await api.get('/job-levels/mappings/company/' + companyId  + '/country/' + countryJobLevel.id);
			if (jobLevelMappingResponse) {
				tempJobLevelMapByCountryLevel.push({
					countryJobLevelId: countryJobLevel.id,
					companyJobLevelMapping: jobLevelMappingResponse.data,
				});
			}
		}
		return tempJobLevelMapByCountryLevel;
	}
	
	const returnCountryOptions = () => {
		
		let countryOptions = [];
		
		fetchCountries().then(function (countriesResponse) {
			for (const country of countriesResponse) {
				countryOptions.push({
					label: (country.name),
					value: (country),
				});
			}
		});
		
		return countryOptions;
	}
	
	const onCountrySelected = (event, deck) => {
		
		showCountrySelector(false);
		
		fetchCountryJobLevels(event)
			.then(function(countryJobLevels) {
				setIsLoading(true);
				setCountryJobLevels(countryJobLevels.data);
				api.get('/job-levels/company/' + parseInt(match.params.id), {headers: {client: match.params.id}})
					.then(function (companyJobLevels) {
						setCompanyJobLevels(companyJobLevels.data);
						setIsLoading(false);
					}).catch(function (error) {
					console.log(error);
				});
		});
	}
	
	function handleSelectedChange(id) {
		setSelectedValue(id);
	}

	return (
		<Fragment>
			{
				isLoading
					? <Loading/>
					: <Fragment>
						<Header showClientNavigation={true} showImmigrationNavigation={false} displayName={companyDisplayName}
						        link={"/select-company"}/>
						<form>
							<div className="container mt-5">
								<h3 className="text-secondary text-bold pb-3">Manage Client Job Level Mappings</h3>
							</div>
							{ countrySelector ?
							<div className="form-group px-5">
								<label htmlFor="country-select" className="text-secondary" >SELECT COUNTRY TO MANAGE PERMITS</label>
								<Select
									options={returnCountryOptions()}
									onChange={(event) => onCountrySelected(event, deck, showCountrySelector)}
								/>
							</div>
								: null }
						</form>
						
						<div className="card-deck justify-content-center px-5 w-80">
							{
								countryJobLevels.map((countryJobLevel, index) => {
									
									let companyJobLevelOptions = [];
									for (const companyJobLevel of companyJobLevels) {
										companyJobLevelOptions.push({
											label: (companyJobLevel.name),
											value: (companyJobLevel.id),
										});
									}
									
									return (
										<div className="row justify-content-center pt-4" style={{width:'80%'}}>
											<div className="container card bg-light mb-3">
													<div className="card-body d-flex" key={index}>
															
															<div className="gw__job-level-mapping-select-container">
																<div className="col">
																	<label className="text-secondary">Mapping:</label>
																	<p>{countryJobLevel.name}</p>
																</div>
																
																<div className="col">
																	<Select
																		options={companyJobLevelOptions}
																		onChange={(event) => handleSelectedChange(event.value)}
																	/>
																</div>
																
																<div>{'\u00A0'}</div>
																
																<div className="col">
																	
																	<button className="btn btn-primary" onClick={() =>
																		createJobLevelMapping(countryJobLevel.id, selectedValue)}
																	>Map
																	</button>
																	<button className="ml-1 btn btn-danger" onClick={() =>
																		deleteJobLevelMapping(countryJobLevel.id, selectedValue)}
																	>Unmap
																	</button>
																	
																	<div>{'\u00A0'}</div>
																	
																</div>
															</div>

															{/* Show already mapped  */}
															<select disabled multiple className="form-control gw__job-level-mappings">
																{countryJobLevelMappings.length > 0 &&
																	<React.Fragment>
																		{countryJobLevelMappings.filter(mapping => mapping.countryJobLevelId === countryJobLevel.id)[0]
																			.companyJobLevelMapping
																			.map((companyJobLevel, companyJobLevelIndex) =>
																			<option key={companyJobLevelIndex}>{companyJobLevel.name}</option>
																		)}
																	</React.Fragment>
																}
															</select>

													</div>
											</div>
										</div>
									);
								})
							}
						</div>
					</Fragment>
			}
		</Fragment>
	);
}

export default ManageCompanyJobLevelMappings;
