import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { api } from '../../api';
import { createRequirementFieldsOfStudy, createSpecialRequirement, deleteRequirement, createExistingJobPositionRequirement, createClientSpecificQuestionRequirement} from '../../services/requirementServices';
import { fetchCountryJobLevels } from '../../services/CountryService';
import { blacklistRequirement, fetchJobPositions } from '../../services/CompanyService';
import Header from '../../general/navigation/Header';
import Loading from '../../general/components/Loading.js';
import Consts from '../../Config/Consts';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import Select from 'react-select';

const RequirementsList = () => {
  const [country, setCountry] = useState(null);
  const [countryId] = useState(localStorage.getItem('countryId'));
  const [fieldOfStudyErrorMessage, setFieldOfStudyErrorMessage] = useState('');
  const [isFieldOfStudyConfigured, setIsFieldOfStudyConfigured] = useState(false);
  const [isFieldOfStudyModalOpen, setIsFieldOfStudyModalOpen] = useState(false);
  const [isLevelRequirementConfigured, setIsLevelRequirementConfigured] = useState(false);
  const [isSalaryRequirementConfigured, setIsSalaryRequirementConfigured] = useState(false);
  const [isExistingJobPositionConfigured, setIsExistingJobPositionConfigured] = useState(false);
  const [isClientSpeicifcQuestionConfigured,setIsClientSpecificQuestionConfigured ] = useState(false);
  
  const [levelRequirementErrorMessage, setLevelRequirementErrorMessage] = useState('');
  const [isLevelRequirementModalOpen, setIsLevelRequirementModalOpen] = useState(false);
  const [isSalaryRequirementModalOpen, setIsSalaryRequirementModalOpen] = useState(false);
  const [isExistingJobPositionModalOpen, setIsExistingJobPositionModalOpen] = useState(false);
  const [isClientSpecificQuestionModalOpen, setIsClientSpecificQuestionModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [regionId] = useState(localStorage.getItem('regionId'));
  const [requirements, setRequirements] = useState([]);
  const [requirementToDecommissionId, setRequirementToDecommissionId] = useState(null);

  const [ companysId, setCompanysId ] = useState([]);
  const [ companyId, setCompanyId ] = useState(null);

  useEffect(() => {
    if (!countryId) {
      setRedirectTo('/');
    } else {
      setIsLoading(true);
      fetchFieldsOfStudyData();
      fetchJobLevels();
      fetchCountryData();
      fetchRequirementsData();
      fetchCompanyId();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFieldsOfStudyData = async () => {
    const fieldsOfStudyResponse = await api.get(`/fields-of-study`); // const countryJobLevels = await fetchCountryJobLevels(countryId);

    if (fieldsOfStudyResponse.data.length < 1) {
      setFieldOfStudyErrorMessage('Please create a field of study before activating this requirement.');
    }
  };

  const fetchJobLevels = async () => {
    const countryJobLevelsResponse = await fetchCountryJobLevels(countryId);

    if (!countryJobLevelsResponse || countryJobLevelsResponse.length < 1) {
      setLevelRequirementErrorMessage ('Please create a job level before activating this requirement.');
    }
  };

  const fetchCountryData = async () => {
    const countriesResponse = await api.get(`/countries`);
    if (countriesResponse) {
      const country = countriesResponse.data.filter(country => country.id.toString() === countryId.toString())[0];
      setCountry(country);
    }
  };

  const fetchCompanyId = async () => {
    const companyResponse = await api.get(`/companies`);
    let companyIds = [];
    if(companyResponse){
    companyResponse.data.forEach(company => (
      companyIds.push({
        value: company.name,
        label: company.name,
        company: company,
      })
    ));
      setCompanysId(companyIds);
    }
  };

  const fetchRequirementsData = async () => {
    setIsFieldOfStudyConfigured(false);
    setIsLevelRequirementConfigured(false);
    setIsSalaryRequirementConfigured(false);
    setIsExistingJobPositionConfigured(false);
    setIsClientSpecificQuestionConfigured(false);
    
    const requirementsResponse = await api.get(`/countries/${countryId}/regions/${regionId}/requirements`);
    if (requirementsResponse) {
      const containsFieldOfStudyQuestion = requirementsResponse.data.filter(requirement => requirement.type === 'fieldOfStudy')[0];
      const containsLevelRequirement = requirementsResponse.data.filter(requirement => requirement.type === 'jobLevel')[0];
      const containsSalaryRequirement = requirementsResponse.data.filter(requirement => requirement.type === 'salaryThreshold')[0];
      const containsExistingJobPosition = requirementsResponse.data.filter(requirement => requirement.type === 'existingJobPosition')[0];
      const containsClientSpecificQuestion = requirementsResponse.data.filter(requirement => requirement.type === 'clientSpecificQuestion')[0];

      setRequirements(requirementsResponse.data);

      if (containsFieldOfStudyQuestion) {
        setIsFieldOfStudyConfigured(true);
      }

      if (containsLevelRequirement) {
        setIsLevelRequirementConfigured(true);
      }

      if (containsSalaryRequirement) {
        setIsSalaryRequirementConfigured(true);
      }

      if (containsExistingJobPosition) {
        setIsExistingJobPositionConfigured(true);
      }

      if (containsClientSpecificQuestion) {
        setIsClientSpecificQuestionConfigured(true);
      }
    }

    setIsLoading(false);
  };

  const decommissionRequirement = async () => {
    setIsLoading(true);
    await deleteRequirement(requirementToDecommissionId);
    fetchRequirementsData();
    toggleDecommissionModal();
  };

  const toggleDecommissionModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleAddFieldOfStudyRequirementClick = async () => {
    setIsLoading(true);

    const requirementToBeCreated = {
      name: 'Field of Study',
      description: 'The most relevant field of study',
      question: 'What is the candidate’s most relevant field of study?',
      type: 'fieldOfStudy',
      region: regionId,
    };

    await createRequirementFieldsOfStudy(requirementToBeCreated);

    toggleFieldOfStudyModal();
    fetchRequirementsData();
  };

  const toggleFieldOfStudyModal = () => {
    setIsFieldOfStudyModalOpen(!isFieldOfStudyModalOpen);
  };

  const handleAddLevelRequirementClick = async () => {
    setIsLoading(true);

    const requirementToBeCreated = {
      name: 'Level',
      description: 'The job level requirement',
      question: 'What is the candidate’s job level required?',
      type: 'jobLevel',
      region: regionId,
    };

    await createSpecialRequirement(requirementToBeCreated);

    toggleLevelRequirementModal();
    fetchRequirementsData();
  };

  const handleAddSalaryClick = async () => {
    setIsLoading(true);

    const requirementToBeCreated = {
      name: 'Salary',
      description: 'The salary requirement',
      question: 'Salary',
      type: 'salaryThreshold',
      region: regionId,
    };

    await createSpecialRequirement(requirementToBeCreated);

    setIsSalaryRequirementModalOpen(!isSalaryRequirementModalOpen);
    fetchRequirementsData();
  };

  const handleAddExistingJobPositionClick = async () => {
    if(!companyId) {
      window.alert('Please select a company');
    } else {
    setIsLoading(true);
    const requirementToBeCreated = {
      name: 'Existing Job Position',
      description: 'Please begin typing the Google job family to see options. Check go/activejobcodes if there are any questions, otherwise contact <a href="mailto:immigration@google.com">immigration@google.com</a> for a custom review. <br /> <br /> If the candidate does not currently work for Google, please make sure you select "N/A - New Hire".',
      question: 'What is the job family of the position which the candidate already holds abroad?',
      type: 'existingJobPosition',
      region: regionId,
      prefix: '',
    };
    const values = await fetchJobPositions(companyId.company.id)
    const correctValue =  values.sort((a, b) => a.jobFamily.localeCompare(b.jobFamily)).map(jobPosition => {
      return {
        id: undefined,
        weight: undefined,
        value: jobPosition.jobFamily,
        };
    });

    requirementToBeCreated.values = correctValue;
  
    const requirement = await createExistingJobPositionRequirement(requirementToBeCreated);
    
    if(requirement) {
      let allCompanyNotGoogle = companysId.filter(company => company.value !== Consts.GOOGLE)

      allCompanyNotGoogle.forEach(company => {
        blacklistRequirement(company.company.id, requirement.data.id)
      })
    }

    setIsExistingJobPositionModalOpen(!isExistingJobPositionModalOpen);
    fetchRequirementsData();
  }
};

  const handleAddClientSpecificQuestionClick = async () => {
    if(!companyId) {
      window.alert('Please select a company');
    } else {
    setIsLoading(true);
    const requirementToBeCreated = {
      name: 'Candidate ID',
      description: 'Please enter the candidate ID',
      question: 'What is the candidate ID?',
      type: 'clientSpecificQuestion',
      region: regionId,
      prefix: '',
    };
    const requirement = await createClientSpecificQuestionRequirement(requirementToBeCreated);
    
    if(requirement) {
      let allCompanyNotExpedia = companysId.filter(company => company.value !== Consts.EXPEDIA)

      allCompanyNotExpedia.forEach(company => {
        blacklistRequirement(company.company.id, requirement.data.id)
      })
    }

    setIsClientSpecificQuestionModalOpen(!isClientSpecificQuestionModalOpen);
    fetchRequirementsData();
  }
};

  const toggleLevelRequirementModal = () => {
    setIsLevelRequirementModalOpen(!isLevelRequirementModalOpen);
  };

  const toggleExistingJobPositionModal = () => {
    setCompanyId(null);
    setIsExistingJobPositionModalOpen(!isExistingJobPositionModalOpen);
  };

  const toggleClientSpecificQuestionModal = () => {
    setCompanyId(null);
    setIsClientSpecificQuestionModalOpen(!isClientSpecificQuestionModalOpen);
  };
  
  const handleCompanyChange = (selectedOption) => {
    setCompanyId(selectedOption);
  };

  return (
    isLoading
    ? <Loading />
    : redirectTo
      ? <Redirect to={redirectTo} />
      : country && requirements &&
        <Fragment>
          <Header showClientNavigation ={false} showImmigrationNavigation={true} displayName={country.name} link={`/select-country`}/>
          <div className="container">
            <div className="row">
              <div className="col-sm-12 d-flex justify-content-between">
              <h3 className="mr-5 gw__section-headings">Requirements</h3>
                <Link to={`/countries/${country.id}/requirements/create`}>
                  <button type="button" className="btn btn-outline-green">
                    <AddCircleOutlineOutlinedIcon className="gw__add-icon"/>
                    NEW REQUIREMENT
                  </button>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 mb-5">
                {
                  requirements ?
                    <Fragment>
                      <table className="table table-hover mt-5 mb-5">
                        <thead>
                          <tr>
                            <th scope="col">NAME</th>
                            <th scope="col">DESCRIPTION</th>
                            <th scope="col">TYPE</th>
                            <th></th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            requirements.map((requirement, index) => {
                              return (
                                <tr key={index}>
                                  <td className="gw__table-items">{requirement.requirementRevisions[0].name}</td>
                                  <td className="gw__table-items" >{requirement.requirementRevisions[0].description}</td>
                                  <td className="gw__table-items">{requirement.type}</td>
                                  {
                                    (requirement.type === 'fieldOfStudy') || (requirement.type === 'jobLevel')
                                    ? <td />
                                    : <td>
                                        <Link className="gw__nav_links gw__edit-permit-button" to={`/countries/${country.id}/requirements/${requirement.id}/edit`}>
                                          <svg width="2em" height="2em" viewBox="0 0 18 18" className="bi bi-pencil" fillRule="currentColor" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" d="M11.293 1.293a1 1 0 0 1 1.414 0l2 2a1 1 0 0 1 0 1.414l-9 9a1 1 0 0 1-.39.242l-3 1a1 1 0 0 1-1.266-1.265l1-3a1 1 0 0 1 .242-.391l9-9zM12 2l2 2-9 9-3 1 1-3 9-9z"/>
                                            <path fillRule="evenodd" d="M12.146 6.354l-2.5-2.5.708-.708 2.5 2.5-.707.708zM3 10v.5a.5.5 0 0 0 .5.5H4v.5a.5.5 0 0 0 .5.5H5v.5a.5.5 0 0 0 .5.5H6v-1.5a.5.5 0 0 0-.5-.5H5v-.5a.5.5 0 0 0-.5-.5H3z"/>
                                          </svg>
                                        </Link>
                                      </td>
                                  }
                                  <td>
                                    <HighlightOffOutlinedIcon className="gw__delete-permit-icon" onClick={() => { setRequirementToDecommissionId(requirement.id); toggleDecommissionModal();}}/>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                      <div className="col-12 d-flex justify-content-center">
                        {
                          !isSalaryRequirementConfigured &&
                          <Fragment>
                            <div className="row mr-1">
                              <div className="col-12 d-flex justify-content-center">
                                <button className="btn btn-primary" onClick={() => setIsSalaryRequirementModalOpen(!isSalaryRequirementModalOpen)}>
                                  + Add Salary Requirement
                                </button>
                              </div>
                            </div>
                          </Fragment>
                        }
                        
                        {
                          !isFieldOfStudyConfigured &&
                          <Fragment>
                            <div className="row mr-1">
                              <div className="col-12 d-flex justify-content-center">
                                <button className="btn btn-primary" onClick={toggleFieldOfStudyModal} disabled={fieldOfStudyErrorMessage}>
                                  + Add Field of Study Requirement
                                </button>
                              </div>
                            </div>
                          </Fragment>
                        }
                        {
                          !isLevelRequirementConfigured &&
                          <Fragment>
                            <div className="row">
                              <div className="col-12">
                                <button className="btn btn-primary" onClick={toggleLevelRequirementModal} disabled={levelRequirementErrorMessage}>
                                  + Add Level Requirement
                                </button>
                              </div>
                            </div>
                          </Fragment>
                        }
                        {
                          !isExistingJobPositionConfigured &&
                          <Fragment>
                            <div className="row ml-1">
                              <div className="col-12">
                                <button className="btn btn-primary" onClick={toggleExistingJobPositionModal} >
                                  + Add Existing Job Position Requirement
                                </button>
                              </div>
                            </div>
                          </Fragment>
                        }
                        {
                          !isClientSpeicifcQuestionConfigured &&
                          <Fragment>
                            <div className="row ml-1">
                              <div className="col-12">
                                <button className="btn btn-primary" onClick={toggleClientSpecificQuestionModal} >
                                  + Add Client Specific Question
                                </button>
                              </div>
                            </div>
                          </Fragment>
                        }
                      </div>

                      {/* Error messages */}
                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <p className="error-message">{fieldOfStudyErrorMessage}</p>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                          <p className="error-message">{levelRequirementErrorMessage}</p>
                        </div>
                      </div>
                    </Fragment>
                  :
                    <p>There are no requirements configured for this country.</p>
                }
              </div>
            </div>
          </div>
          {/* Field of Study Modal */}
          <Modal isOpen={isFieldOfStudyModalOpen} toggle={toggleFieldOfStudyModal}>
            <ModalHeader>
              Create Field of Study Requirement
            </ModalHeader>
            <ModalBody>
              <b>Are you sure you want to create a field of study requirement?</b><br />
              Field of study to job position mappings need to be configured in the client management section.
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-cancel" onClick={toggleFieldOfStudyModal}>No, Cancel</button>
              <button type="button" className="btn btn-primary" onClick={handleAddFieldOfStudyRequirementClick}>Yes, Create</button>
            </ModalFooter>
          </Modal>

          {/* Decommission Modal */}
          <Modal isOpen={isModalOpen} toggle={toggleDecommissionModal}>
            <ModalHeader>
              Decommission Requirement
            </ModalHeader>
            <ModalBody>
              <b>Are you sure you want to decommission this requirement?</b><br />
              This requirement and its associated data will be deleted across all clients.
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-cancel" onClick={toggleDecommissionModal}>No, Cancel</button>
              <button type="button" className="btn btn-primary" onClick={decommissionRequirement}>Yes, Remove</button>
            </ModalFooter>
          </Modal>

          {/* Level Requirement Modal */}
          <Modal isOpen={isLevelRequirementModalOpen} toggle={toggleLevelRequirementModal}>
            <ModalHeader>
              Create Level Requirement
            </ModalHeader>
            <ModalBody>
              <b>Are you sure you want to create a level requirement?</b><br />
              Mappings between client and country levels need to be configured in the client management section.
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-cancel" onClick={toggleLevelRequirementModal}>No, Cancel</button>
              <button type="button" className="btn btn-primary" onClick={handleAddLevelRequirementClick}>Yes, Create</button>
            </ModalFooter>
          </Modal>

          {/* Salary Requirement Modal */}
          <Modal isOpen={isSalaryRequirementModalOpen} toggle={() => setIsSalaryRequirementModalOpen(!isSalaryRequirementModalOpen)}>
            <ModalHeader>
              Create Salary Requirement
            </ModalHeader>
            <ModalBody>
              <b>Are you sure you want to create a salary requirement?</b><br />
              Salary ranges are configured in the work permit section.
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-cancel" onClick={() => setIsSalaryRequirementModalOpen(!isSalaryRequirementModalOpen)}>No, Cancel</button>
              <button type="button" className="btn btn-primary" onClick={handleAddSalaryClick}>Yes, Create</button>
            </ModalFooter>
          </Modal>
          {/* Existing Job Position Requirement Modal */}
          <Modal isOpen={isExistingJobPositionModalOpen} toggle={toggleExistingJobPositionModal}>
            <ModalHeader>
              Create Existing Job Position Requirement
            </ModalHeader>
            <div>
            Select Company
              <Select 
              options={companysId.filter(company => company.value === Consts.GOOGLE)}
              onChange={handleCompanyChange}
              // value={companyId.label}
              />
            </div>
            <ModalBody>
              <b>Are you sure you want to create an existing job position requirement?</b><br />
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-cancel" onClick={toggleExistingJobPositionModal}>No, Cancel</button>
              <button type="button" className="btn btn-primary" onClick={handleAddExistingJobPositionClick}>Yes, Create</button>
            </ModalFooter>
          </Modal>
          
          {/* Client Specific Question Modal */}
          <Modal isOpen={isClientSpecificQuestionModalOpen} toggle={toggleClientSpecificQuestionModal}>
            <ModalHeader>
              Create Client Specific Question
            </ModalHeader>
            <div>
            Select Company
              <Select 
              options={companysId.filter(company => company.value === Consts.EXPEDIA)}
              onChange={handleCompanyChange}
              />
            </div>
            <ModalBody>
              <b>Are you sure you want to create a client specific question?</b><br />
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-cancel" onClick={toggleClientSpecificQuestionModal}>No, Cancel</button>
              <button type="button" className="btn btn-primary" onClick={handleAddClientSpecificQuestionClick}>Yes, Create</button>
            </ModalFooter>
          </Modal>
        </Fragment>
  );
};

export default RequirementsList;
