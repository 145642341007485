import React, { useEffect, useReducer } from 'react';
import { Route, HashRouter } from 'react-router-dom';

// CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

 // Context and Reducers
 import { CompanyContext } from './context/CompanyContext';
 import { CountryContext } from './context/CountryContext';
 import { CompanyReducer } from './reducers/CompanyReducer';
 import { CountryReducer } from './reducers/CountryReducer';

 // Services
 import { fetchCompanies } from './services/CompanyService';
 import { fetchCountries } from './services/CountryService';

// Client
import ManageAnnouncements from './client/announcement/ManageAnnouncements';
import ManageCompanyJobLevelMappings from './client/job-level/ManageCompanyJobLevelMappings';
import ManageCompanyJobLevels from './client/job-level/ManageCompanyJobLevels';
import JobMapping from './client/job-position/JobMapping';
import JobPositionToFieldOfStudyMapping from './client/job-position/JobPositionToFieldOfStudyMapping';
import ManageJobPositions from './client/job-position/ManageJobPositions';
import ManageCompanyRequirements from './client/requirement/ManageCompanyRequirements';
import OrderPermits from './client/work-permit/OrderPermits';

// Country
import VisaCitizenshipProcessingTime from './country/citizenship-processing-time/VisaCitizenshipProcessingTime';
// import VisaGovernmentProcessing from './country/government-processing-time/VisaGovernmentProcessing';
import VisaGovernmentProcessingTime from './country/government-processing-time/VisaGovernmentProcessingTime';

import CountryJobLevel from './country/job-level/CountryJobLevel';
import CountryJobLevelForm from './country/job-level/CountryJobLevelForm';
import WorkPermitOutput from './country/recruiter-output/WorkPermitOutput';
import RequirementForm from './country/requirement/RequirementForm';
import RequirementsList from './country/requirement/RequirementsList';
import AddWorkPermit from './country/work-permit/AddWorkPermit';
import WorkPermitsList from './country/work-permit/WorkPermitsList';

// General
import SelectCompany from './general/country-client-select/SelectCompany';
import SelectCountry from './general/country-client-select/SelectCountry';
import FieldsOfStudyForm from './general/field-of-study/FieldsOfStudyForm';
import ManageFieldsOfStudy from './general/field-of-study/ManageFieldsOfStudy';
import LandingPage from './general/landing-page/LandingPage';
import ManageClients from './general/manage-clients/ManageClients';
import EditCountry from './general/manage-countries/EditCountry';
import ManageCountries from './general/manage-countries/ManageCountries';
import LoginPage from './general/session/LoginPage';
import SessionManager from './general/session/SessionManager';
import AnnouncementForm from './client/announcement/AnnouncementForm';

require('dotenv').config();

const App = () => {
  const initialCompanyState = {
    companies: [],
    selectedCompany: null,
  };

  const initialCountryState = {
    countries: [],
    selectedCountry: null,
  };

  const companyReducer = (state, action) => CompanyReducer(state, action);
  const countryReducer = (state, action) => CountryReducer(state, action);
  const [companyState, companyDispatch] = useReducer(companyReducer, initialCompanyState);
  const [countryState, countryDispatch] = useReducer(countryReducer, initialCountryState);

  // TODO fetch data only after login is confirmed
  useEffect(() => {
    // Check if there are no companies or countries in the context
    if (companyState.companies.length <= 0) {
      fetchCompanyData();
    }

    if (countryState.countries.length <= 0) {
      fetchCountryData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCountryData = async () => {
    const countriesResponse = await fetchCountries();

    if (countriesResponse) {
      countryDispatch({
        type: 'FETCH_COUNTRIES',
        countries: countriesResponse,
      });
    }
  };

  const fetchCompanyData = async () => {
    const companiesResponse = await fetchCompanies();

    if (companiesResponse) {
      companyDispatch({
        type: 'FETCH_COMPANIES',
        companies: companiesResponse,
      });
    }
  };

  return (
    <CountryContext.Provider value={[countryState, countryDispatch]}>
      <CompanyContext.Provider value={[companyState, companyDispatch]}>
        <SessionManager />
        <HashRouter basename="/">
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/login" component={LoginPage} />

          {/* Management Routes */}
          <Route exact path="/clients" component={ManageClients} />
          <Route exact path="/countries" component={ManageCountries} />
          <Route exact path="/countries/:id/edit" component={EditCountry} />
          <Route exact path="/countries/create" component={EditCountry} />
          <Route exact path="/manage/fields-of-study" component={ManageFieldsOfStudy} />
          <Route exact path="/manage/fields-of-study/create" component={FieldsOfStudyForm} />
          <Route exact path="/manage/fields-of-study/:id/edit" component={FieldsOfStudyForm} />

          {/* Client Configuration Routes */}
          <Route exact path="/select-company" component={SelectCompany}/>
          <Route exact path="/companies/:id/requirements" component={ManageCompanyRequirements} />
	        <Route exact path="/companies/:id/job-levels/" component={ManageCompanyJobLevels} />
	        <Route exact path="/companies/:id/job-level-mappings/" component={ManageCompanyJobLevelMappings} />
          <Route exact path="/companies/:id/job-positions/create" component={ManageJobPositions} />
          <Route exact path="/companies/:id/job-positions/positions-to-job-codes" component={JobMapping} />
          <Route exact path="/companies/:id/job-positions/positions-to-fields-of-study" component={JobPositionToFieldOfStudyMapping} />
          <Route exact path="/companies/:id/order-permits" component={OrderPermits} />
          <Route exact path="/companies/:id/announcements" component={ManageAnnouncements} />
          <Route exact path="/companies/:id/announcements/create" component={AnnouncementForm} />
          <Route exact path="/companies/:id/announcements/:announcementId/edit" component={AnnouncementForm} />

          {/* Immigration Law Configuration Routes */}
          <Route exact path="/select-country" component={SelectCountry}/>
          <Route exact path="/countries/:countryId/citizenship-processing-times" component={VisaCitizenshipProcessingTime} />
          <Route exact path="/countries/:countryId/job-levels" component={CountryJobLevel} />
          <Route exact path="/countries/:countryId/job-levels/create" component={CountryJobLevelForm} />
          <Route exact path="/countries/:countryId/job-levels/:id/edit" component={CountryJobLevelForm} />
          <Route exact path="/countries/:countryId/government-processing-times" component={VisaGovernmentProcessingTime} />
          <Route exact path="/countries/:countryId/output" component={WorkPermitOutput} />
          <Route exact path="/countries/:countryId/requirements" component={RequirementsList} />
          <Route exact path="/countries/:countryId/requirements/create" component={RequirementForm} />
          <Route exact path="/countries/:countryId/requirements/:id/edit" component={RequirementForm} />
          <Route exact path="/countries/:countryId/work-permits" component={WorkPermitsList} />
          <Route exact path="/countries/:countryId/work-permits/create" component={AddWorkPermit} />
          <Route exact path="/countries/:countryId/work-permits/:id/edit" component={AddWorkPermit} />


          {/* Authentication Redirect */}
          <Route exact path="/id_token=*" component={LoginPage} />
        </HashRouter>
      </CompanyContext.Provider>
    </CountryContext.Provider>
  );
}

export default App;
