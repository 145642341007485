export const CompanyReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_COMPANIES':
      return {
        ...state,
        companies: action.companies,
      };
    case 'SELECT_COMPANY':
      return {
        ...state,
        selectedCompany: action.selectedCompany,
      };
    case 'SET_ANNOUNCEMENTS':
      return {
        ...state,
        companyAnnouncements: action.companyAnnouncements,
      };
    default:
      return state;
  }
};
