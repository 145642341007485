import React, { Component } from 'react';
import {api} from '../../api';
import {Collapse, Modal, ModalBody} from 'reactstrap';
import Header from '../../general/navigation/Header';
import Select from 'react-select';
import Loading from '../../general/components/Loading';

class EditCountry extends Component {
  constructor(props) {
    super(props);
    this.state = {
      regions: [],
      jobCodes: [],
      countries: [],
      countryName: '',
      countryCode: '',
      isPointsBased: false,
      countryToEdit: null,
      collapseJobCodes: false,
      collapseCountry: true,
      modal: false,
      deleteModal: false,
      modalText: '',
      companies: [],
      selectedCompanies: [],
      isLoading: false,
      jobCodesDirty: false
    };
  }

  componentDidMount() {
    if(this.props.match.params.id){
      this.setState({
        isLoading: true
      }, () => this.setCountryAndRegion())
    }
    api.get(`/countries`)
      .then(response => {
        if (response.data) {
          this.setState({countries: response.data})
        }
      });
    this.getCompanies();
  }

  async getCompanies() {
    let response = await api.get(`/companies`);
    let companies =[];
    let companiesWithDestination = [];
    for (const company of response.data){
      companies.push({value: company.name, label: company.name, company: company});
      if (this.props.match.params.id) {
        let destinationFound = company.destinationCountries.find(country => country.id.toString() === this.props.match.params.id.toString());
        if (destinationFound) {
          companiesWithDestination.push({value: company.name, label: company.name, company: company});
        }
      }
    }
    this.setState({
      companies: companies,
      selectedCompanies: companiesWithDestination,
      isLoading: false
    })
  }

  async setCountryAndRegion(){
    const countryId = this.props.match.params.id;
    let countryToEdit = null;
    let response = await api.get(`/countries/` + countryId);
    countryToEdit =  response.data;

    let regions = [];
    let jobCodes = [];
    if(countryToEdit) {
      if(countryToEdit.regions.length > 1) {
        for(const region of countryToEdit.regions) {
          regions.push({name: region.name, code: region.code, id: region.id});
        }
      }
      if(countryToEdit.jobCodes.length > 0) {
        for(const jobCode of countryToEdit.jobCodes) {
          jobCodes.push({name: jobCode.name, id: jobCode.id});
        }
      }
    }
    
    this.setState({
      countryToEdit: countryToEdit,
      countryName: countryToEdit.name,
      countryCode: countryToEdit.code,
      isPointsBased: countryToEdit.isPointsBased,
      regions: regions,
      jobCodes: jobCodes
    })
  }

  addRegion = (event) => {
    event.preventDefault();
    const {regions} = this.state;
    regions.push({name: '', code: '', id: null});
    this.setState({regions});
  }

  addJobCode = (event) => {
    event.preventDefault();
    const {jobCodes} = this.state;
    jobCodes.push({name: '', id: null});
    this.setState({jobCodes});
  }

  updateRegion(updateType, index, event) {
    const {regions} = this.state;
    regions[index][updateType]= event.target.value;
    this.setState({regions});
  }

  updateJobCode(updateType, index, event) {
    const {jobCodes} = this.state;
    jobCodes[index][updateType]= event.target.value;
    this.setState({jobCodes});
  }

  removeJobCode = (index) => {
    const {jobCodes} = this.state;
    jobCodes.splice(index, 1)
    this.setState({jobCodes, jobCodesDirty: true});
  }

  removeRegion = (index) => {
    const {regions} = this.state;
    regions.splice(index, 1)
    this.setState({regions});
  }

  updateCountryName = (event) => {
    this.setState({countryName: event.target.value})
  }

  updateCountryCode = (event) => {
    this.setState({countryCode: event.target.value})
  }

  setPointsBased = () => {
    this.setState({isPointsBased: !this.state.isPointsBased})
  }

  submitCountry = (event) => {
    event.preventDefault();
    let duplicateCountryAlert = "Country already exists!";
    let duplicateCountries = this.state.countries.filter(country => country.name === this.state.countryName);
    if(duplicateCountries.length >= 1 && !this.state.countryToEdit){
      alert(duplicateCountryAlert);
    } else {
      if(this.state.countryToEdit) {
        // Checks if the country being edited matches the one found in the DB.
        // Prevent user from creating duplicates via the edit screen, but will allow user to rename a country.
        if(duplicateCountries.filter(duplicateCountry => duplicateCountry.id === this.state.countryToEdit.id)[0] 
          || duplicateCountries.length === 0){
          this.setState({
            isLoading: true
          }, () => this.updateCountry());
        } else {
          alert(duplicateCountryAlert);
        }
      } else {
        this.setState({isLoading: true}, () => {
          const countryObject = {
            name: this.state.countryName,
            code: this.state.countryCode,
            isPointsBased: this.state.isPointsBased,
          };
          api.post('/countries', countryObject)
          .then(response => this.createRegions(response.data))
          .catch(error => {});
        });
      }
    }
  }

  submitJobCodes = () => {
    if(this.state.countryToEdit) {
      this.setState({
        isLoading: true
      }, () => this.updateJobCodes())
    }
  }

  async updateCountry() {
    const countryObject = {
      name: this.state.countryName,
      code: this.state.countryCode,
      isPointsBased: this.state.isPointsBased,
    }
    await api.patch(('/countries/' + this.state.countryToEdit.id), countryObject);
    this.updateRegions();
  }

  async updateRegions () {
    await api.patch(('/countries/' + this.state.countryToEdit.id + '/regions'), this.state.regions);
    await this.updateCompaniesWithCountryDestination(this.state.countryToEdit.id);
    this.setState({
        isLoading: false,
        modalText: 'Country updated'
    }, () => this.toggleModal())
  }

  async updateCompaniesWithCountryDestination(countryId) {
    let selectedCompanies = [];
    for (const company of this.state.selectedCompanies){
      selectedCompanies.push(company.company);
    }
    await api.patch(('/countries/' + countryId + '/update-company-destinations'), {companies: selectedCompanies});
  }

  async updateJobCodes () {
    await api.patch(('/countries/' + this.state.countryToEdit.id + '/job-codes'), this.state.jobCodes);
    this.setState({
      isLoading: false,
      jobCodesDirty: false,
      modalText:"Job codes updated"
    }, () => this.toggleModal());
  }

  async createRegions(country){
    if(this.state.regions.length > 1) {
      for (const region of this.state.regions) {
        await api.post(('/countries/'+country.id+'/regions'), {name: region.name, code: region.code}).catch(error => {});
      }
    } else if (this.state.regions.length <= 1) {
        await api.post(('/countries/' + country.id + '/regions'), {name: country.name, code: country.code}).catch(error => {});
    }
    this.updateCompaniesWithCountryDestination(country.id);
    this.setState({
      countryToEdit: country,
      isLoading: false,
      modalText: 'Country has been created'
    }, () => this.toggleModal());
  }

  toggleJobCodes = () => {
    this.setState(state => ({ collapseJobCodes: !state.collapseJobCodes }));
  }

  toggleCountryDetails = () => {
    this.setState(state => ({ collapseCountry: !state.collapseCountry }));
  }

  onClickCancel = () => {
    window.location.replace('/#/countries');
  }

  onClickCancelJobCodes = () => {
    this.toggleJobCodes();
    this.setCountryAndRegion();
  }

  checkRegionsFilled = () => {
    let regionsFilledCorrectly = true;
    for (const region of this.state.regions) {
      let duplicateRegion = this.state.regions.filter(countryRegion => countryRegion.name === region.name);
      if(duplicateRegion.length > 1) {
        regionsFilledCorrectly = false;
      }
      if (!region.name || !region.code) {
        regionsFilledCorrectly = false;
      }
    }
    return regionsFilledCorrectly;
  }

  checkJobCodesFilledAndSubmit = (event) => {
    event.preventDefault();
    let jobCodesFilledCorrectly = true;
    let alertMessage = null;
    for (const jobCode of this.state.jobCodes) {
      let duplicateJobCode = this.state.jobCodes.filter(code => code.name === jobCode.name);
      if(duplicateJobCode.length > 1) {
        jobCodesFilledCorrectly = false;
        alertMessage = 'Job Code already exists. Please enter a unique job code.';
      }
      if (!jobCode.name) {
        jobCodesFilledCorrectly = false;
        alertMessage = 'Please ensure a name is entered for all job codes before submission.';
      }
    }
    if(!this.state.jobCodes.length > 0 && !this.state.jobCodesDirty){
      jobCodesFilledCorrectly = false;
      alertMessage = 'Please enter at least 1 job code before submission.';
    }

    if(jobCodesFilledCorrectly){
      this.submitJobCodes();
    } else {
      alert(alertMessage);
    }
  }

  toggleModal = () => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  toggleDeleteModal = (open) => {
    this.setState({
      deleteModal: open
    });
  }

  deleteCountry = () => {
    api.patch(('/countries/' + this.state.countryToEdit.id), {isDeleted: true})
    .then(() => {window.location.replace('/#/countries')});
  }

  closeModal = () => {
    this.toggleModal();
    if(this.state.countryToEdit) {
      this.setState({
        isLoading: true
      }, () => {
        this.setCountryAndRegion();
        this.getCompanies();
        if(!this.props.match.params.id){
          this.redirectToCountryEdit();
        }
      })
    }
  }

  redirectToCountryEdit = () =>{
    window.location.replace('/#/countries/'+ this.state.countryToEdit.id+'/edit');
  }

  selectCompany= (event) => {
    let companies = [];

    if (event) {
      for (const company of event){
        companies.push(company);
      }
    }

    this.setState({
      selectedCompanies: companies
    })
  }

  render() {
    return (
      <React.Fragment>
        {this.state.isLoading ?
        <Loading />
        :
        <React.Fragment>
        <Header showClientNavigation ={false} showImmigrationNavigation={false} displayName={null} link={null}/>
        <div className="pb-5">
          <div className="container manage-countries-container ">
            <h3 className="text-secondary font-weight-bold mt-5">{this.state.countryToEdit ? "Edit country" : "Create country"}</h3>
            <div className="d-flex justify-content-center">
            <form className="w-50 pt-5">
              <div className= "d-flex justify-content-between collapse-toggle" onClick={this.toggleCountryDetails}>
                <h4 className="font-weight-bold text-secondary mt-5 mb-2">Country Details</h4>
                <h4 className="font-weight-bold text-secondary mt-5" >{this.state.collapseCountry ? "-" : "+"}</h4>
              </div>
              {this.state.companies.length > 0 &&
                <Collapse isOpen={this.state.collapseCountry}>
                    <div className="form-group w-100">
                      <label className="text-secondary" htmlFor="countryName">Country Name</label>
                      <input onChange={this.updateCountryName} value={this.state.countryName} type="text" className="form-control" id="countryName"/>
                    </div>
                    <div className="form-group w-100">
                      <label className="text-secondary" htmlFor="countryCode">Country Code</label>
                      <input onChange={this.updateCountryCode} value={this.state.countryCode} type="text" className="form-control" id="countryCode"/>
                    </div>
                    {/* <div className="form-group w-100">
                      <div className="form-check">
                        <input checked={this.state.isPointsBased} onChange={this.setPointsBased} className="form-check-input" type="checkbox" value="" id="pointsCheck" />
                        <label className="form-check-label text-secondary" htmlFor="pointsCheck">
                          Points-based?
                        </label>
                      </div>
                    </div> */}
                    <label className="form-check-label text-secondary mt-4 mb-2">Select companies for which this is a destination country:</label>
                    <Select className="mb-5" isMulti={true} options={this.state.companies} onChange={(event) => this.selectCompany(event)} defaultValue={this.state.selectedCompanies}/>
                    {/* <h4 className="text-secondary"> Add Regions</h4>
                    <p className="text-secondary font-weight-light">If regions are applicable for this country, ensure more than one region is entered.</p>
                    {this.state.regions.map((region, index) => 
                      <div className="d-flex" key={index}>
                        <div className="form-group w-50 mr-1">
                          <label className="text-secondary">Region Name</label>
                          <input value={this.state.regions[index].name} type="text" index={index} onChange={(event) => this.updateRegion("name", index, event)} className="form-control"/>
                        </div>
                        <div className="form-group w-50">
                          <label className="text-secondary">Region Code</label>
                          <input value={this.state.regions[index].code} onChange={(event) => this.updateRegion("code", index, event)} type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <button type="button" onClick={() => this.removeRegion(index)} className="btn btn-danger h-50 ml-1 remove-region-button">X</button>
                        </div>
                      </div>
                    )}
                    <div className="d-flex justify-content-center">
                      <button disabled={!this.state.countryToEdit && (!this.state.countryName || !this.state.countryCode)} onClick={this.addRegion} className="w-25 btn btn-primary m-2">Add Region</button>
                    </div> */}
                    <div className="d-flex justify-content-center">
                      <button disabled ={!this.checkRegionsFilled() || !this.state.countryName || !this.state.countryCode || this.state.regions.length === 1} type="submit" onClick={this.submitCountry} className="mt-2 w-25 btn btn-secondary">Submit </button>
                      <button onClick={this.onClickCancel} className="mt-2 ml-3 w-25 btn btn-danger">Cancel</button>
                    </div>
                  </Collapse>
                }
                <hr></hr>
              <div className= "d-flex justify-content-between collapse-toggle" onClick={this.toggleJobCodes}>
                <h4 className="font-weight-bold text-secondary mt-5 mb-2">Job Codes</h4>
                <h4 className="font-weight-bold text-secondary mt-5">{this.state.collapse ? "-" : "+"}</h4>
              </div>
                <Collapse isOpen={this.state.collapseJobCodes}>
                  {this.state.jobCodes.map((jobCode, index) => 
                    <div className="d-flex" key={index}>
                      <div className="form-group w-50 mr-1">
                        <label className="text-secondary">Job Code Name</label>
                        <input value={this.state.jobCodes[index].name} type="text" index={index} onChange={(event) => this.updateJobCode("name", index, event)} className="form-control"/>
                      </div>
                      <div className="form-group">
                        <button type="button" onClick={() => this.removeJobCode(index)} className="btn btn-danger h-50 ml-1 remove-region-button">X</button>
                      </div>
                    </div>
                  )}
                    {!this.state.countryToEdit ?
                      <div className="d-flex justify-content-center mt-3 mb-3 text-danger">
                        <span>Please submit country before adding job codes.</span>
                      </div>
                      :
                      <React.Fragment>
                        <div className="d-flex justify-content-center mt-3 mb-3 text-danger">
                          <button onClick={this.addJobCode} className="w-25 btn btn-primary m-2">Add Job Code</button>
                        </div>
                        <div className="d-flex justify-content-center">
                          <button type="submit" onClick={this.checkJobCodesFilledAndSubmit} className="mt-2 w-25 btn btn-secondary">Submit </button>
                          <button onClick={this.onClickCancelJobCodes} className="mt-2 ml-3 w-25 btn btn-danger">Cancel</button>
                        </div>
                      </React.Fragment>
                    }                                      
                </Collapse>
                <hr></hr>
            </form> 
            </div>
            {this.state.countryToEdit &&
                <div className="d-flex justify-content-center">
                  <button onClick={() => this.toggleDeleteModal(true)} className="mt-2 ml-3 btn btn-danger">Delete Country</button>
                </div>
            }
          </div>
        </div>
        </React.Fragment>
        }
        <Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className}>
          <ModalBody>
          <div className="d-flex justify-content-center">
            {this.state.modalText}
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button type="button" className="btn btn-primary" onClick={this.closeModal}>Close</button>
          </div>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.deleteModal} toggle={this.toggleDeleteModal} className={this.props.className}>
          <ModalBody>
          <div className="d-flex justify-content-center">
          {'Are you sure you want to delete this country?'}
          </div>
          <div className="d-flex justify-content-center mt-5">
            <button type="button" className="btn btn-danger mr-2" onClick={this.deleteCountry}>Delete</button>
            <button type="button" className="btn btn-primary" onClick={() => this.toggleDeleteModal(false)}>Cancel</button>
          </div>
          </ModalBody>
        </Modal>
      </React.Fragment>
    )
  }
}

export default EditCountry;