import React, {Component} from 'react';
import 'draft-js/dist/Draft.css'
import {convertFromRaw, convertToRaw, ContentState, Editor, EditorState, RichUtils, CompositeDecorator} from 'draft-js';
import jsonChecker from '../components/JsonChecker';

const INLINE_STYLES = [
  {
    title: 'B', style: 'BOLD', isSelected: false
  },
  {
    title: 'I', style: 'ITALIC', isSelected: false
  },
  {
    title: 'U', style: 'UNDERLINE', isSelected: false
  }
];

const BLOCK_STYLES = [
  {
    title: 'Title', style: 'header-one', isSelected: false
  },
  {
    title: 'List', style: 'unordered-list-item', isSelected: false
  }
];

class RichTextEditor extends Component {
  constructor(props) {
    super(props);

    // To find and style the links within the editor.
    const decorator = new CompositeDecorator([
      {
        strategy: findLinkEntities,
        component: Link,
      },
    ]);

    if (props.section.content) {
      //if content is in JSON, create the state with JSON
      if(jsonChecker(props.section.content)){
        this.state = {
          editorState: EditorState.createWithContent(convertFromRaw(JSON.parse(props.section.content)), decorator),
          showURLInput: false,
          urlValue: '',
        };
      }else {
        //if content is in plain text, create the state with createFromText
        this.state = {
          editorState : EditorState.createWithContent(ContentState.createFromText(props.section.content),decorator),
          showURLInput: false,
          urlValue: '',
        };
      }
    } else {
      this.state = {
        editorState: EditorState.createEmpty(decorator),
        showURLInput: false,
        urlValue: '',
      };
    }

    this.promptForLink = this._promptForLink.bind(this);
    this.onURLChange = (e) => this.setState({urlValue: e.target.value});
    this.confirmLink = this._confirmLink.bind(this);
    this.onLinkInputKeyDown = this._onLinkInputKeyDown.bind(this);
    this.removeLink = this._removeLink.bind(this);
  }

  focus = () => this.refs.editor.focus();

  onChange = editorState => {
    this.setState({
      editorState
    }, () => {
      this.checkActiveStyles();
      this.props.handleRichTextFormatInput(this.props.section, JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())));
    });
  }
  
  onInlineStyleMouseDown = (e) => {
    e.preventDefault();
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, e.target.name));
  }

  onBulletPointsClick = (e) => {
    e.preventDefault();
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, e.target.name));
  }

  checkActiveStyles() {
    const inlineStyles = this.state.editorState.getCurrentInlineStyle();

    INLINE_STYLES.forEach(inlineStyle => {
      let isActive = inlineStyles.has(inlineStyle.style);
      let result = INLINE_STYLES.find(is => is.style === inlineStyle.style);

      if (isActive) {
        result.isSelected = true;
      } else {
        result.isSelected = false;
      }
    });

    this.state.editorState.getSelection();
    
    this.forceUpdate();
  }

  // Handles the u, i and b commands
  handleKeyCommand = (command, editorState) => {
    let newState;
    newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return 'handled';
    }

    return 'non-handled';
  };

  /**
   * On clicking the AddLink button on a selection of text, prompt the user to input the link.
   * @param {event} e 
   */
  _promptForLink(e) {
    e.preventDefault();
    const {editorState} = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      // Get the location to place the link. Entity is bound in an index location of the text it is applied to.
      const contentState = editorState.getCurrentContent();
      const startKey = editorState.getSelection().getStartKey();
      const startOffset = editorState.getSelection().getStartOffset();
      const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
      const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

      let url = '';
      if (linkKey) {
        const linkInstance = contentState.getEntity(linkKey);
        url = linkInstance.getData().url;
      }

      this.setState({
        showURLInput: true,
        urlValue: url,
      }, () => {
        setTimeout(() => this.refs.url.focus(), 0);
      });
    }
  }

  /**
   * Set the link by creating an Entity.
   * @param {event} e 
   */
  _confirmLink(e) {
    e.preventDefault();
    const {editorState, urlValue} = this.state;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      'LINK',
      'MUTABLE',
      {
        target: '_blank', // Set all links configured to automatically open in new tab.
        url: urlValue,
      });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
    this.setState({
      editorState: RichUtils.toggleLink(
        newEditorState,
        newEditorState.getSelection(),
        entityKey
      ),
      showURLInput: false,
      urlValue: '',
    }, () => {
      setTimeout(() => this.refs.editor.focus(), 0);
    });
  }

  _onLinkInputKeyDown(e) {
    if (e.which === 13) {
      this._confirmLink(e);
    }
  }

  /**
   * Remove the link from the selected piece of text.
   * @param {event} e 
   */
  _removeLink(e) {
    e.preventDefault();
    const {editorState} = this.state;
    const selection = editorState.getSelection();
    if (!selection.isCollapsed()) {
      this.setState({
        editorState: RichUtils.toggleLink(editorState, selection, null),
      });
    }
  }

  render() {
    const inlineStyleButtons = INLINE_STYLES.map(inlineStyle => {
      return <button key={inlineStyle.title} className={inlineStyle.isSelected ? 'active' : ''} onClick={this.onInlineStyleMouseDown} name={inlineStyle.style}>{inlineStyle.title}</button>
    });

    const blockStyleButtons = BLOCK_STYLES.map(blockStyle => {
      return <button key={blockStyle.title} className={blockStyle.isSelected ? 'active' : ''} onClick={this.onBulletPointsClick} name={blockStyle.style}>{blockStyle.title}</button>
    });

    let urlInput;
    if (this.state.showURLInput) {
      urlInput =
        <div>
          <input
            onChange={this.onURLChange}
            ref="url"
            style={styles.urlInput}
            type="text"
            value={this.state.urlValue}
            onKeyDown={this.onLinkInputKeyDown}
          />
          <button onClick={this.confirmLink}>
            Confirm
          </button>
      </div>;
    }

    return (
      <div className="rtf-container mt-4">
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          handleKeyCommand={this.handleKeyCommand}
          placeholder='Please enter the section content, please avoid using the "<" and ">" symbols, as you will not be allowed to save the changes.'
          ref="editor"
        />
        <div className="toolbar">
          {blockStyleButtons}
          {inlineStyleButtons}
          <button onClick={this.promptForLink} style={{marginRight: 10}}>Add Link</button>
          <button onClick={this.removeLink}>Remove Link</button>
          {urlInput}
        </div>
      </div>
    );
  }
}

/**
 * Finds the link entities within the contentBlock and appropriately gives them the Link styling.
 * @param {Object} contentBlock 
 * @param {Object} callback 
 * @param {Object} contentState 
 */
function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        contentState.getEntity(entityKey).getType() === 'LINK'
      );
    },
    callback
  );
}

const Link = (props) => {
  const {url} = props.contentState.getEntity(props.entityKey).getData();
  return (
    <a href={url} style={styles.link}>
      {props.children}
    </a>
  );
};

const styles = {
  link: {
    color: '#3b5998',
    textDecoration: 'underline',
  },
};

export default RichTextEditor;
