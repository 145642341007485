import React from 'react';
import { Link } from 'react-router-dom';
import {
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import TextareaAutosize from 'react-textarea-autosize';

import Loading from '../../general/components/Loading';
import { createCountryJobLevel, updateCountryJobLevel } from '../../services/CountryService';
import Header from '../../general/navigation/Header';

const CountryJobLevelForm = (props) => {
  const [countryId] = React.useState(localStorage.getItem('countryId'));
  const [country, setCountry] = React.useState(undefined);
  const [jobLevels, setJobLevels] = React.useState(undefined);
  const [region] = React.useState(localStorage.getItem('regionId'));
  const [isEditing, setIsEditing] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isNameValid, setIsNameValid] = React.useState(true);
  const [name, setName] = React.useState('');
  const [description, setDescription] = React.useState('');

  React.useEffect(() => {
    if (!props.location.state) {
      window.location.replace(`/#/countries/${countryId}/job-levels`);
    }
    if (props.location.state && props.location.state.country) {
      setCountry(props.location.state.country);
    };

    if (props.location.state && props.location.state.jobLevels) {
      setJobLevels(props.location.state.jobLevels);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.location.state]);

  React.useEffect(() => {
    const id = props.match.params.id && parseInt(props.match.params.id);
    if (jobLevels && id) {
      jobLevels.forEach((item) => {
        if (id === item.id) {
          setIsEditing(true);
          setName(item.name);
          setDescription(item.description);
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobLevels]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  }

  const handleInput = (event) => {
    if (event.target.name === 'name') {
      setName(event.target.value);
      setIsNameValid(true);
    } else if (event.target.name === 'description') {
      setDescription(event.target.value)
    }
  }

  const isFormValid = () => {
    let isFormValid = true;

    if (!name || !description) {
      isFormValid = false;
    }

    return isFormValid;
  }

  const validateJobLevelName = () => {
    let isNameValid = true;

    jobLevels.forEach(level => {
      if (level.name === name) {
        isNameValid = false;
      }
    });

    return isNameValid;
  }

  const createJobLevel = async () => {
    try {
      if (validateJobLevelName() && countryId) {
        setIsModalOpen(!isModalOpen);
        setIsLoading(true);
    
        const jobLevelToBeCreated = {
          name,
          description,
          country: countryId,
        };
  
        await createCountryJobLevel(jobLevelToBeCreated);
  
        setIsLoading(false);
        window.location.replace(`/#/countries/${country.id}/job-levels`);
      } else {
        setIsLoading(false)
        setIsNameValid(false);
        setIsModalOpen(!isModalOpen);
      }

    } catch (error) {
      setIsLoading(false);
      countryId && window.location.replace(`/#/countries/${country.id}/job-levels`)
    }
  }

  const updateJobLevel = async () => {
    try {
      if (countryId) {
        setIsModalOpen(!isModalOpen);
        setIsLoading(true);
  
      const jobLevelToBeUpdated = {
        name,
        description,
        country: countryId,
      };
  
      countryId && await updateCountryJobLevel(props.match.params.id, jobLevelToBeUpdated);
      setIsLoading(false)
      window.location.replace(`/#/countries/${country.id}/job-levels`);
      } else {
        setIsLoading(false)
        setIsNameValid(false);
        setIsModalOpen(!isModalOpen);
      }
  
    } catch (error) {
      setIsLoading(false);
      countryId && window.location.replace(`/#/countries/${country.id}/job-levels`)
    }
  }

  return (
    <React.Fragment>
      {country &&
      <React.Fragment>
        <Header
          showClientNavigation ={false}
          showImmigrationNavigation={true}
          displayName={country.name + (country.regions.length > 1 ? " - " + region.name : "")}
          link={"/select-country"}
        />  
        {isLoading
         ? <Loading /> 
         : <div className="container">
          <h3 className="gw__section-headings">Create Job Level</h3>
          <form>
            <div className="formGroup mt-5">
              <div className="row">
                <div className="col-sm-6 align-self-center">
                  <label className="gw__form-sections" htmlFor="name">Name</label>
                </div>
                <div className="col-sm-6">
                  <input
                    className={"form-control" + (!isNameValid ? " gw__input-error" : "")}
                    type="text"
                    name="name"
                    maxLength={255}
                    value={name}
                    onChange={handleInput}
                    placeholder="Enter job level name"
                  />
                  {!isNameValid && <p className="gw__invalid-name">NAME IN USE</p>}
                </div>
              </div>
            </div>
            <div className="formGroup mt-5">
              <div className="row">
                <div className="col-sm-6 align-self-center">
                  <label className="gw__form-sections" htmlFor="description">Description</label>
                </div>
                <div className="col-sm-6">
                  <TextareaAutosize
                    className="form-control text-area-sizing"
                    type="text"
                    name="description"
                    maxLength={255}
                    value={description}
                    onChange={handleInput}
                    placeholder="Enter job level description"
                  />
                </div>
              </div>
            </div>
          </form>

          <hr className="mt-5"/>

          <div className="row mt-5">
            <div className="col-sm-12">
              <div className="gw__form-footer mb-5">
                <Link to={`/countries/${country.id}/job-levels`}>
                  <button type="button" className="btn btn-cancel">Cancel</button>
                </Link>
                <button type="button" className="btn btn-primary" onClick={toggleModal} disabled={!isFormValid()}>
                  {isEditing ? 'Update' : 'Save'}
                </button>
              </div>
            </div>
          </div>
          <Modal isOpen={isModalOpen} toggle={toggleModal} className={props.className}>
            <ModalBody>
              {
                isEditing ?
                  <p>Are you sure you want to update this job level?</p>
                :
                  <p>This job level will apply to all work permits listed on this country, would you like to proceed?</p>
              }
            </ModalBody>
            <ModalFooter>
              <button type="button" className="btn btn-secondary" onClick={toggleModal}>Close</button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={isEditing ? updateJobLevel : createJobLevel}
              >
                {isEditing ? 'Update' : 'Save'}
              </button>
            </ModalFooter> 
          </Modal>
        </div>
      }
      </React.Fragment>
      }
    </React.Fragment>
  );
}

export default CountryJobLevelForm;
