import {UserAgentApplication} from 'msal';
import { api } from '../api';
import azure from '../azure';

const loginUser = async (user) => {
  // const countriesResponse = await api.get(`/countries`);
  const loginResponse = await api.post(`/auth/login`, user)
  return loginResponse;
};

const logoutUser = async () => {
  if (process.env.REACT_APP_SSO_ENABLED !== 'false') {
    const msalInstance = new UserAgentApplication({
      auth: {
        clientId: azure.appId,
        authority: azure.ssoURL,
        redirectUri: azure.redirectUri,
      }
    });

    api.post(`/auth/logout`)
      .then(logoutResponse => {
        if (logoutResponse) {
          msalInstance.logout();
          window.location.replace('/#/');
          window.location.reload();
        }
      });

  } else {
    api.post(`/auth/logout`)
      .then(() => {
        window.location.replace('/#/');
        window.location.reload();
      });
  }
};

const extendUserSession = async () => {
  const res = await api.post('/auth/extend');
  return res;
};

export {
  loginUser,
  logoutUser,
  extendUserSession,
}
