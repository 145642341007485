import { api } from '../api';

const fetchCountries = async () => {
  const countriesResponse = await api.get(`/countries`);
  return countriesResponse ? countriesResponse.data : [];
};

const fetchCountry = async (countryId) => {
  const countryResponse = await api.get(`/countries/${countryId}`);
  return countryResponse ? countryResponse.data : null;
};

const fetchCountryJobLevels = async (countryId) => {
  const countrJobLevelsResponse = await api.get(`/job-levels/countries/${countryId}`);
  return countrJobLevelsResponse ? countrJobLevelsResponse.data : [];
};

const fetchCountryJobCodes = async (countryId) => {
  const countryJobCodeResponse = await api.get(`/countries/${countryId}/job-codes`);
  return countryJobCodeResponse ? countryJobCodeResponse.data : [];
};

const fetchCountryRequirements = async (countryId, regionId) => {
  const countryRequirementsResponse = await api.get(`/countries/${countryId}/regions/${regionId}/requirements`);
  return countryRequirementsResponse ? countryRequirementsResponse.data : [];
}

const createCountryJobLevel = async (jobLevel) => {
  return await api.post(`/job-levels/countries`, jobLevel)
};

const updateCountryJobLevel = async (id, jobLevel) => {
  return api.patch(`/job-levels/countries/${id}`, jobLevel)
};

const decommissionJobLevel = async (id) => {
  return await api.delete(`/job-levels/countries/${id}`)
};

const fetchSalaryRanges = async (workPermitId) => {
  const salaryRangeResponse = await api.get(`/salary-ranges/work-permit/${workPermitId}`);
  return salaryRangeResponse ? salaryRangeResponse.data : [];
};

const createSalaryRange = async (salaryRanges) => {
  return await api.post(`/salary-ranges/create-salary-ranges`, salaryRanges);
};

const deleteSalaryRanges = async (salaryRanges) => {
  if (salaryRanges.length > 0) {
    return await api.delete(`/salary-ranges/delete-salary-ranges`, {data: salaryRanges});
  }
  return null;
};

const fetchWorkPermit = async (workPermitId) => {
  const workPermitResponse = await api.get(`work-permits/${workPermitId}`);
  return workPermitResponse ? workPermitResponse : null;
}

export {
  createCountryJobLevel,
  decommissionJobLevel,
  fetchCountry,
  fetchCountryJobLevels,
  fetchCountryJobCodes,
  fetchCountryRequirements,
  fetchCountries,
  updateCountryJobLevel,
  createSalaryRange,
  fetchSalaryRanges,
  deleteSalaryRanges,
  fetchWorkPermit,
}
