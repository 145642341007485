import React, { useContext, useEffect, useState } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import Header from '../../general/navigation/Header';
import { fetchCompanies, createCompany } from '../../services/CompanyService';
import {Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const ManageClients = () => {
  const [companyState, companyDispatch] = useContext(CompanyContext);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!companyState.companies) {
      const companiesResponse = fetchCompanies();
      companyDispatch({
        type: 'FETCH_COMPANIES',
        companies: companiesResponse,
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Save the updated company data to the database.
   * @param {*} companyData 
   */
  const handleCompanyUpdate = (companyData) => {
    const company = {
      id: companyData.id,
      name: companyData.name,
      salaryThreshold: companyData.salaryThreshold === '' ? null : companyData.salaryThreshold,
    }
    createCompany(company);
    setIsModalOpen(!isModalOpen);
  };

  /**
   * Update the company input values.
   * @param {*} event 
   * @param {*} index 
   */
  const handleInputChange = (event, index) => {
    switch (event.target.name) {
      case 'salaryThreshold':
        companyState.companies[index].salaryThreshold = event.target.value;
        break;
      case 'companyName':
        companyState.companies[index].name = event.target.value;
        break;
      default:
        break;
    }

    const tempCompanies = companyState.companies;
    companyDispatch({
      type: 'FETCH_COMPANIES',
      companies: tempCompanies,
    });
  };

  return (
    <React.Fragment>
      <Header showClientNavigation ={false} showImmigrationNavigation={false} displayName={null} link={null}/>
      <div className="container manage-countries-container p-5 d-flex">
      <h3 className="gw__section-headings mb-5">Current clients</h3>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Salary Threshold</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {companyState.companies.map((company, index) =>
            <tr key={index}>
              <td>{company.id}</td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="companyName"
                  maxLength={255}
                  value={company.name}
                  onChange={(event) => handleInputChange(event, index)}
                />
              </td>
              <td>
                <input
                  className="form-control"
                  type="text"
                  name="salaryThreshold"
                  placeholder="Salary Threshold"
                  value={company.salaryThreshold ? company.salaryThreshold : ''}
                  onChange={(event) => handleInputChange(event, index)}
                />
              </td>
              <td><button onClick={() => handleCompanyUpdate(company)} className="btn btn-primary">Save</button></td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
      <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)}>
        <ModalHeader>
          Company Saved
        </ModalHeader>
        <ModalBody>
          <p>Company data saved successfully</p>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-primary" onClick={() => setIsModalOpen(!isModalOpen)}>Close</button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default ManageClients;