import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { api } from '../../api';
import Header from '../../general/navigation/Header';
import Loading from '../components/Loading';

const FieldsOfStudyForm = ({ match }) => {
  const [fieldOfStudyName, setFieldOfStudyName] = useState('');
  const [fieldOfStudyDescription, setFieldOfStudyDescription] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    if (match.params.id) {
      setIsEditing(true);
      fetchFieldOfStudy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchFieldOfStudy = async () => {
    const fieldOfStudyResponse = await api.get(`/fields-of-study/${match.params.id}`);

    setFieldOfStudyName(fieldOfStudyResponse.data.name);
    setFieldOfStudyDescription(fieldOfStudyResponse.data.description);
  };

  const saveFieldOfStudy = async () => {
    setIsLoading(true);

    const fieldOfStudy = {
      name: fieldOfStudyName,
      description: fieldOfStudyDescription,
    };

    if (isEditing) {
      await api.patch(`/fields-of-study/${match.params.id}`, fieldOfStudy);
    } else {
      await api.post(`/fields-of-study`, fieldOfStudy);
    }

    setRedirectTo('/manage/fields-of-study');
  };

  return (
    redirectTo
    ? <Redirect to={redirectTo} />
    : <Fragment>
        <Header showClientNavigation ={false} showImmigrationNavigation={false} displayName={null} link={null}/>
        {
          isLoading
          ? <Loading />
          : <Fragment>
              <form>
                <div className="form-group mt-5">
                  <div className="row">
                    <div className="col-6 offset-3">
                      <h3 className="text-secondary font-weight-bold">Field of Study Details</h3>
                    </div>
                  </div>
                  <div className="row d-flex justify-content-center mt-3">
                    <div className="col-6">
                      <label className="fields-of-study-label" htmlFor="field-of-study-name">FIELD OF STUDY NAME</label>
                      <input
                        className="form-control"
                        id="field-of-study-name"
                        placeholder="Enter field of study name"
                        type="text"
                        maxLength={255}
                        value={fieldOfStudyName}
                        onChange={event => setFieldOfStudyName(event.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group mt-3">
                  <div className="row d-flex justify-content-center mt-3">
                    <div className="col-6">
                      <label className="fields-of-study-label" htmlFor="field-of-study-description">FIELD OF STUDY DESCRIPTION</label>
                      <textarea
                        className="form-control"
                        id="field-of-study-description"
                        placeholder="Enter field of study description"
                        type="text"
                        value={fieldOfStudyDescription}
                        onChange={event => setFieldOfStudyDescription(event.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </form>

              <div className="row d-flex justify-content-center">
                <div className="col-6">
                  <button className="btn btn-primary mr-3" onClick={saveFieldOfStudy}>{isEditing ? 'Update' : 'Save'}</button>
                  <button className="btn btn-secondary"><Link to="/manage/fields-of-study">Cancel</Link></button>
                </div>
              </div>
            </Fragment>
        }
      </Fragment>
  )
};

export default FieldsOfStudyForm;
