import React, { Fragment, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { CountryContext } from '../../context/CountryContext';

const CountryMappingSelect = ({ workPermit, selectedWorkPermitSourceRegions, setSelectedWorkPermitSourceRegions }) => {
    const countryState = useContext(CountryContext);

    const [allRegions, setAllRegions] = useState([]);
    const [regionDropdown, setRegionDropdown] = useState([]);
    const [selectedSourceRegionValues, setSelectedSourceRegionValues] = useState([]);
    const [pastedString] = useState('');

    useEffect(() => {
        initialiseRegionDropdown();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * Fetch and set-up all region related data. 
     */
    const initialiseRegionDropdown = async () => {
        const allRegionsTemp = [];
        const regionDropdownTemp = [];

        if (countryState[0].countries.length > 0) {
            for (const country of countryState[0].countries) {
                // Separate the regions from the countries in order to build a dropdown containing only regions.
                for (const region of country.regions) {
                    setSelectedRegion(region, allRegionsTemp, regionDropdownTemp);
                }
            }
        }
        setRegionDropdown(regionDropdownTemp);
        setAllRegions(allRegionsTemp);

        if (workPermit && workPermit.workPermitSourceRegions) {
            loadSavedSourceRegions(regionDropdownTemp);
        }
    };

    /**
     * Extract the source region mappings stored in workPermit and correctly load them into the 
     * selected values arrays.
     */
    const loadSavedSourceRegions = (regionDropdownList) => {
        // Pull the mapping data from the workPermit object
        const workPermitSourceRegionData = workPermit.workPermitSourceRegions;

        // Load in the existing data/anything already selected, if there's any.
        const copyOfSelectedDropdownValues = selectedSourceRegionValues;
        const copyOfWorkPermitSourceRegions = selectedWorkPermitSourceRegions;

        for (const workPermitSourceRegion of workPermitSourceRegionData) {
            if (workPermitSourceRegion.region) {
                // Load the data into the selectedDropdownValue array
                const foundDropdownValue = regionDropdownList.find(r => r.value === workPermitSourceRegion.region.id);
                copyOfSelectedDropdownValues.push(foundDropdownValue);

                // Load the data into the selectedWorkPermitSourceRegion so it can be sent to the backend to process
                copyOfWorkPermitSourceRegions.push(workPermitSourceRegion.region);
            }
        }

        setSelectedSourceRegionValues(copyOfSelectedDropdownValues);
        setSelectedWorkPermitSourceRegions(copyOfWorkPermitSourceRegions);
    };

    /**
     * Correctly map and populate the select field when a list of countries are pasted.
     * @param {String} pastedString 
     */
    const handleSourceRegionPaste = async (pastedString) => {
        // Split the string with delimiter ;
        if (pastedString.indexOf(';') > 0) {
            const tempRegionDropdownValues = [...selectedSourceRegionValues];
            let tempSelectedSourceRegions = selectedWorkPermitSourceRegions;

            let splitSourceRegionArray = pastedString.split(';');

            for (const pastedRegion of splitSourceRegionArray) {
                // Find the corresponding region object, so it can be added to the list of regions to be mapped.
                const foundRegionObject = allRegions.find(r => r.name.toLowerCase().trim() === pastedRegion.toLowerCase().trim());
                // Find the corresponding region from the dropdown based on its name and the dropdown's label.
                const foundRegionDropdownValue = regionDropdown.filter(r => r.label.toLowerCase().trim() === pastedRegion.toLowerCase().trim())[0];

                // Only push new values as 'selected' if they're not already selected.
                const foundRegionSelect = tempRegionDropdownValues.find(r => (r && foundRegionDropdownValue) ? r.value === foundRegionDropdownValue.value : null);
                if (!foundRegionSelect) {
                    tempRegionDropdownValues.push(foundRegionDropdownValue);
                    tempSelectedSourceRegions.push(foundRegionObject);
                }
            }

            setSelectedSourceRegionValues(tempRegionDropdownValues);
            setSelectedWorkPermitSourceRegions(tempSelectedSourceRegions);
        }
    };

    /**
     * Set the selected source region to the array of regions to be submitted to the backend.
     * @param {Event} event 
     */
    const handleSourceRegionSelection = async (event) => {
        const tempSelectedRegions = [];
        const workPermitSourceRegions = [];

        if (event) {
            event.forEach(e => {
                tempSelectedRegions.push({
                    label: e.label,
                    value: e.value
                });

                // Find the region object and add to the workPermitSourceRegion array.
                const regionObject = allRegions.filter(region => region.id === e.value)[0];
                workPermitSourceRegions.push(regionObject);
            });
        }

        setSelectedWorkPermitSourceRegions(workPermitSourceRegions);
        setSelectedSourceRegionValues(tempSelectedRegions);
    };

    /**
     * Add the region correctly to the arrays when it has been selected.
     * @param {Object} region 
     * @param {Region[]} allRegionsTemp list of all region objects in the database
     * @param {Array} regionDropdownTemp list of all regions in {label, value} pairs for react-select
     */
    const setSelectedRegion = (region, allRegionsTemp, regionDropdownTemp) => {
        // Array of region objects, for later reference/use, in case the object is required.
        allRegionsTemp.push(region);

        // React select dropdown values.
        regionDropdownTemp.push({
            label: region.name,
            value: region.id
        });
    };

    return (
        <Fragment>
            <hr className="mt-5" />
            <div className="formGroup mt-5">
                <div className="row mt-5">
                    <div className="col-sm-6">
                        <label className="gw__form-sections">Select the applicable source regions</label>
                    </div>
                    <div className="col-sm-6">
                        {
                            <Select
                                isMulti
                                onInputChange={handleSourceRegionPaste}
                                inputValue={pastedString}
                                options={regionDropdown}
                                onChange={handleSourceRegionSelection}
                                value={selectedSourceRegionValues}
                            />
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default CountryMappingSelect;
