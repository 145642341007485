import React, {Fragment, useEffect, useState} from 'react';
import { api } from '../../api';
import Header from '../../general/navigation/Header';
import Loading from '../../general/components/Loading';

	const ManageCompanyJobLevels = ({match}) => {
		
		const [isLoading, setIsLoading] = useState(true);
		const [companyDisplayName, setCompanyDisplayName] = useState('');
		const [jobLevels, setJobLevels] = useState([]);
		const [isJobLevelCreated, setIsJobLevelCreated] = useState(false);
		const [isJobLevelDeleted, setIsJobLevelDeleted] = useState(false); // Rerender the page to reflect deleted job level
		
		useEffect(() => {
			fetchCompany().then(function (companyResponse) {
				setCompanyDisplayName(companyResponse.data.name);
				api.get('/job-levels/company/' + parseInt(match.params.id), {headers: {client: match.params.id}})
					.then(function (response) {
						populateCardDeck(jobLevels, setJobLevels, response.data, updateJobLevel, deleteJobLevel)
					}).catch(function (error) {
					console.log('No Job-Levels in Response');
					console.log(error);
				});
			});
			setIsLoading(false);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [isJobLevelCreated, isJobLevelDeleted]);
		
		/**
		 * Gets the current company from the controller based on what the company id in the URL
		 */
		const fetchCompany = async () => {
			return await api.get(`/companies/${match.params.id}`);
		};
		
		const addJobLevelInputField = function () {
			setIsJobLevelCreated(false);
      let key;
      if (jobLevels.length > 0) {
        key = parseInt(jobLevels[jobLevels.length-1].key) + 1;
      } else { key = 1; }
			let new_list = jobLevels.concat(
				<div key={key} className="pt-2" style={{width: "80%"}}>
					<div className="container card bg-light">
						<div className="card-body">
							<h5 className="pt-2">Job-Level</h5>
							
							<small className="text-muted">Name of the Job Level:</small>
							<textarea
								className="form-control"
								id={"name-" + key.toString()}
								maxLength={255}
							/>
							
							<small className="text-muted">Description of the Job Level:</small>
							<textarea
								className="form-control"
								id={"description-" + key.toString()}
							/>
							
							<div className="button-container justify-content-center p-0 mt-3">
								<button className="btn btn-primary mr-3" onClick={() => createJobLevel(key)}>
									Save
								</button>
							</div>
						
						</div>
					</div>
				</div>
			);
			setJobLevels(new_list);
		}
		
		function createJobLevel(key) {
			let job_level = {
				name: document.getElementById('name-' + key).value,
				description: document.getElementById('description-' + key).value,
				companyId: parseInt(match.params.id)
			}
			api.post(`/job-levels/company/` + parseInt(match.params.id) + `/create`, job_level).then(function () {
				setIsJobLevelCreated(true);
				alert('Job level successfully created.');
				return true;
			}).catch(error => function() {
				console.log('Save Failed');
			});
		}
		
		function deleteJobLevel(key) {
			api.delete(`/job-levels/company/` + parseInt(key)).then(function () {
				setIsJobLevelDeleted(!isJobLevelDeleted);
				alert('Job level successfully deleted.');
				return true;
			}).catch(error => function () {
				console.log('Delete Failed');
			});
		}
		
		function updateJobLevel(key) {
			let job_level = {
				name: document.getElementById('name-' + key).value,
				description: document.getElementById('description-' + key).value,
			}
			api.post(`/job-levels/company/` + parseInt(key) + `/update`, job_level).then(function () {
				alert('Job level successfully updated.');
				return true;
			}).catch(error => function () {
				console.log('Update Failed');
			});
		}
		
		return (
			<Fragment>
				{
					isLoading
						? <Loading/>
						: <Fragment>
							<Header showClientNavigation={true} showImmigrationNavigation={false} displayName={companyDisplayName}
							        link={"/select-company"}/>
							<form>
								<div className="container mt-5">
									<h3 className="text-secondary text-bold pb-3">Manage Client Job Levels</h3>
								</div>

								<div className="col-auto">
									<div className="card-deck justify-content-center mb-3">
										{jobLevels}
									</div>
								</div>

								<div className="container mb-5">
									<button className="btn btn-primary" onClick={() => addJobLevelInputField()}>+ Add New Job Level</button>
								</div>
							</form>

						</Fragment>
				}
			</Fragment>
		);
	}
	
	function populateCardDeck(jobLevels, setJobLevels, data, updateJobLevel, deleteJobLevel) {
		let deck = [];
		for (const joblevel of data) {
			deck.push(
			<div key={joblevel.id} className="pt-2" style={{width: "80%"}}>
				<div className="container card bg-light">
					<div className="card-body">
						<h5 className="pt-2">Job-Level</h5>
						
						<small className="text-muted">Name of the Job Level:</small>
						<textarea
							className="form-control"
							id={"name-" + joblevel.id}
							defaultValue={joblevel.name}
							maxLength={255}
						/>
						
						<small className="text-muted">Description of the Job Level:</small>
						<textarea
							className="form-control"
							id={"description-" + joblevel.id}
							defaultValue={joblevel.description}
						/>
						<div className="row pl-3">
							<div className="button-container justify-content-center p-0 mt-3">
								<button className="btn btn-primary mr-3" onClick={() => updateJobLevel(joblevel.id)}>
									Save
								</button>
							</div>
							
							<div className="button-container justify-content-center p-0 mt-3">
								<button className="btn btn-danger mr-3" onClick={() => deleteJobLevel(joblevel.id)}>
									Delete
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			);
		}
		setJobLevels(deck);
	}

export default ManageCompanyJobLevels;
