import React, { Fragment, useContext, useEffect } from 'react';
import { Link, Redirect } from'react-router-dom';
import Header from '../../general/navigation/Header';
import { CompanyContext } from '../../context/CompanyContext';
import Select from 'react-select';

const SelectCompany = () => {
  const [companyState, companyDispatch] = useContext(CompanyContext);

  useEffect(() => {
    companyDispatch({
      type: 'SELECT_COMPANY',
      selectedCompany: null,
    });

    localStorage.removeItem('companyId');
    localStorage.removeItem('regionId');
    localStorage.removeItem('countryId');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Returns an array of companies in value-label pairs as options for the dropdown.
   */
  const returnCompanies = () => {
    const options = [];
    for (const company of companyState.companies) {
      options.push({value: (company), label: (company.name)})
    }
    return options;
  }

  /**
   * Set the selected company once a company has been selected from the dropdown.
   * @param {object} event passed in upon change of company select
   */
  const handleSelectCompany = (event) => {
    // Given the company id from the dropdown variable, find the exact company from the companies in the current state.
    const selectedCompany = event.value;

    // Set the company state to be the company which was selected.
    companyDispatch({
      type: 'SELECT_COMPANY',
      selectedCompany: selectedCompany,
    });

    localStorage.setItem('companyId', selectedCompany.id);
};

  return (
    companyState.companies.length <= 0
    ? <Redirect to="/" />
    : <Fragment>
        <Header showClientNavigation ={false} showImmigrationNavigation={false} displayName={null} link={null}/>
        <div className="select-country-container d-flex justify-content-center align-items-center">
          <form>
            <div className="form-group">
              <div className="label">CLIENT</div>
              <Select 
                className="landing-page-select" 
                onChange={(event) => handleSelectCompany(event)} 
                options={returnCompanies()}
              />
            </div>
          </form>
          {
            companyState.selectedCompany && <Link to={`/companies/${companyState.selectedCompany.id}/job-positions/create`}>Continue</Link>
          }
        </div>
      </Fragment>
  );
};

export default SelectCompany;
