import { api } from '../api';

const createRequirementFieldsOfStudy = async (requirementToBeCreated) => {
  await api.post(`/requirements/fields-of-study`, requirementToBeCreated);
}

const createSpecialRequirement = async (requirementToBeCreated) => await api.post(`/requirements/special-requirement`, requirementToBeCreated);

const createExistingJobPositionRequirement = async (requirementToBeCreated) => await api.post(`/requirements/existing-job-position`, requirementToBeCreated);

const createClientSpecificQuestionRequirement = async (requirementToBeCreated) => await api.post(`/requirements/client-specific-question`, requirementToBeCreated);

const deleteRequirement= async (id) => await api.delete(`/requirements/${id}`);

const createRequirement = async (countryId, requirementToBeCreated) => {
  await api.post(`/requirements`, requirementToBeCreated).catch(error => {
    window.location.replace(`/#/countries/${countryId}/requirements`);
  });
};

const fetchRequirement = async (requirementId) => {
  const requirementResponse = await api.get(`/requirements/${requirementId}`);
  return requirementResponse ? requirementResponse.data : [];
};

const updateRequirement = async (requirementId, requirementToBeUpdated) => await api.patch(`/requirements/${requirementId}`, requirementToBeUpdated);

const createValue = async (valueToCreate) => {
  await api.post(`/values`, valueToCreate).catch(error => {
    console.error(error);
  });
}

const updateValue = async (value) => await api.patch(`/values/${value.id}`, value);

const removeValues = async (valueToBeDeleted) => await api.delete(`/values/${valueToBeDeleted}`);

export {
  createRequirementFieldsOfStudy,
  createSpecialRequirement,
  deleteRequirement,
  createRequirement,
  fetchRequirement,
  updateRequirement,
  createValue,
  updateValue,
  removeValues,
  createExistingJobPositionRequirement,
  createClientSpecificQuestionRequirement,
}