import React, { useEffect, useState, useContext, Fragment } from 'react';
import { CompanyContext } from '../../context/CompanyContext';
import Loading from '../../general/components/Loading';
import Header from '../../general/navigation/Header';
import { fetchCompanyAnnouncements, saveAnnouncement, fetchCompany } from '../../services/CompanyService';
import Select from "react-select";
import { Link } from 'react-router-dom';
import RichTextEditor from '../../general/text-editor/RichTextEditor';
import {api} from '../../api';

const AnnouncementForm = ({ match }) => {
    const [companyState, companyDispatch] = useContext(CompanyContext);
    const [companyId] = useState(match.params.id);
    const [announcementId] = useState(match.params.announcementId);

    const [isLoading, setIsLoading] = useState(true);
    const [isEditing, setIsEditing] = useState(false);

    const [countryOptions, setCountryOptions] = useState([]);

    const [announcementTitle, setAnnouncementTitle] = useState('');
    const [announcementText, setAnnouncementText] = useState('');
    const [announcementType, setAnnouncementType] = useState(1);
    const [announcementCountries, setAnnouncementCountries] = useState([{label: 'All countries', value: 0}]);
    const [isGlobal, setIsGlobal] = useState(true);

    const [isNameValid, setIsNameValid] = useState(true);
    const [isTextValid, setIsTextValid] = useState(true);

    const [isUrlValid, setIsUrlValid] = useState(true);
    const [invalidUrl, setInvalidUrl] = useState('');

    useEffect(() => {
        fetchData();
        setCountryDropdown();

        if (announcementId && companyState.companyAnnouncements) {
            setIsEditing(true);
            const announcement = companyState.companyAnnouncements.filter(announcement => announcement.id === parseInt(announcementId))[0];
            setAnnouncementTitle(announcement.title);
            setAnnouncementText(announcement.text);
            setAnnouncementType(announcement.type);
            setSelectedCountries(announcement);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcementId, companyState.companyAnnouncements]);

    /**
     * If any necessary data is not in the state, retrieve it from the database.
     */
    const fetchData = async () => {
        if (!companyState.selectedCompany) {
            const selectedCompany = await fetchCompany(companyId);

            companyDispatch({
                type: 'SELECT_COMPANY',
                selectedCompany: selectedCompany,
            });
        }

        if (!companyState.companyAnnouncements) {
            const announcementsResponse = await fetchCompanyAnnouncements(companyId);

            companyDispatch({
                type: 'SET_ANNOUNCEMENTS',
                companyAnnouncements: announcementsResponse,
            });
        }

        setIsLoading(false);
    };

    const setCountryDropdown = () => {
        if (companyState.selectedCompany) {
            const countryDropdown = [{label: 'All countries', value: 0}];
            for (const country of companyState.selectedCompany.destinationCountries) {
                countryDropdown.push({
                    label: country.name,
                    value: country.id,
                });
            }
            setCountryOptions(countryDropdown);
        }
    };

    const setSelectedCountries = (announcement) => {
        if (companyState.companyAnnouncements) {
            const announcementCountries = [];
            for (const announcementCountry of announcement.selectedAnnouncementCountries) {
                announcementCountries.push({
                    label: announcementCountry.country.name,
                    value: announcementCountry.country.id,
                });
            }
            if (announcement.isGlobal) {
                announcementCountries.push({
                    label: 'All countries',
                    value: 0,
                });
            }
            setAnnouncementCountries(announcementCountries);
        }
    };

    const handleSave = async () => {
        if (await isFormValid()) {
            setIsLoading(true);
            let globalFlag = isGlobal;
            const countriesToSave = [];
            if (announcementCountries) {
                globalFlag = announcementCountries.find(country => country.value === 0) ? true : false;
                setIsGlobal(globalFlag);
                for (const country of announcementCountries) {
                    if (country.value !== 0) {
                        countriesToSave.push(country.value);
                    }
                }
            }
            const announcementToSave = {
                id: parseInt(announcementId),
                title: announcementTitle,
                text: announcementText,
                type: announcementType,
                isGlobal: globalFlag,
                selectedCountries: countriesToSave,
                company: companyId,
            };

            await saveAnnouncement(announcementToSave);
            setIsLoading(false);
            window.location.replace(`/#/companies/${companyId}/announcements`);
        }
    };

    const handleInput = (event) => {
        switch (event.target.name) {
            case 'title':
                setAnnouncementTitle(event.target.value);
                setIsNameValid(true);
                break;
            case 'text':
                setAnnouncementText(event.target.value);
                setIsTextValid(true);
                break;
            case 'type':
                setAnnouncementType(parseInt(event.target.value));
                break;
            default:
                console.log('Announcement Error: Consult Support');
                break;
        }
    };

    const handleRichTextFormatInput = (announcementText, editorText) => {
        let sectionToEdit = announcementText;
        sectionToEdit.content = editorText;
        setAnnouncementText(sectionToEdit.content);
        setIsTextValid(true);
    }

    const handleCountrySelect = (event) => {
        if (event && event.find(country => country.value === 0)) {
            setIsGlobal(true);
        } else {
            setIsGlobal(false);
        }
        setAnnouncementCountries(event);
    };

    const isFormValid = async () => {
        let isFormValid = true;
        if (announcementTitle === '') {
            setIsNameValid(false);
            isFormValid = false;
        }
        if (announcementText === '') {
            setIsTextValid(false);
            isFormValid = false;
        }
        if((announcementText.includes("<") || announcementText.includes(">")) ||
            announcementTitle.includes("<") || announcementTitle.includes(">")){
            setIsTextValid(false);
            isFormValid = false;
        }
        if (announcementText.includes("url")) {
            //TODO: Add whitelist to DB to avoid hardcoded values.
            let urlWhitelist;
            await api.get('/whitelist').then((res) => urlWhitelist = res.data);
            const entityMap = JSON.parse(announcementText).entityMap;
            const invalidUrls = []; 
            for (const [key] of Object.entries(entityMap)) {
                const url = entityMap[key].data.url;
                if (!urlWhitelist.includes(url)) {
                    setIsTextValid(false);
                    isFormValid = false;
                    setIsUrlValid(false);
                    invalidUrls.push(url);
                }
            }
            const invalidUrlList = JSON.stringify(invalidUrls).slice(1, JSON.stringify(invalidUrls).length -1);
            setInvalidUrl(invalidUrlList);
        }
        return isFormValid;
    };

    return (
        isLoading
            ? <Loading />
            : <Fragment>
                <Header
                    showClientNavigation={true}
                    showImmigrationNavigation={false}
                    displayName={companyState.selectedCompany.name}
                    link={"/select-company"}
                />

                <div className="container">
                    {
                        isEditing
                            ? <h3 className="gw__section-headings">Edit Announcement</h3>
                            : <h3 className="gw__section-headings">Create Announcement</h3>
                    }

                    <form className="mb-5 pb-5">
                        {/* Announcement Name */}
                        <div className="formGroup mt-5">
                            <div className="row">
                                <div className="col-sm-6 align-self-center">
                                    <label className="gw__form-sections" htmlFor="name">Title</label>
                                </div>
                                <div className="col-sm-6">
                                    <input
                                        className={"form-control" + (!isNameValid ? " gw__input-error" : "")}
                                        type="text"
                                        name="title"
                                        value={announcementTitle}
                                        onChange={handleInput}
                                        placeholder="Enter announcement title"
                                    />
                                    {!isNameValid && <p className="gw__invalid-name">INVALID NAME</p>}
                                </div>
                            </div>
                        </div>

                        {/* Announcement Body */}
                        <div className="formGroup mt-5">
                            <div className="row">
                                <div className="col-sm-6 align-self-center">
                                    <label className="gw__form-sections" htmlFor="name">Announcement Text
                                        <p className="gw__secondary-label">The text to appear on the announcement banner</p></label>
                                </div>
                                <div className="col-sm-6">
                                    <RichTextEditor
                                        className={"form-control" + (!isTextValid ? " gw__input-error" : "")}
                                        type="text"
                                        name="text"
                                        section={{content: announcementText}}
                                        handleRichTextFormatInput={handleRichTextFormatInput}
                                    />
                                    {isUrlValid ? !isTextValid && <p className="gw__invalid-name">{"INVALID ANNOUNCEMENT (EITHER EMPTY OR INCLUDES '<' OR '>')"}</p> : !isTextValid && <p className="gw__invalid-name">INVALID URL {invalidUrl} FOR THE ANNOUNCEMENT</p>}
                                </div>
                            </div>
                        </div>

                        {/* Announcement Countries */}
                        <div className="formGroup mt-5">
                            <div className="row">
                                <div className="col-sm-6 align-self-center">
                                    <label className="gw__form-sections" htmlFor="name">Countries
                                        <p className="gw__secondary-label">Countries not active for {companyState.selectedCompany.name} will not appear</p></label>
                                </div>
                                <div className="col-sm-6">
                                    <Select
                                        isMulti
                                        options={countryOptions}
                                        id="country-select"
                                        onChange={handleCountrySelect}
                                        defaultValue={announcementCountries}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Announcement Type */}
                        <div className="formGroup mt-5">
                            <div className="row">
                                <div className="col-sm-6 align-self-center">
                                    <label className="gw__form-sections" htmlFor="name">Announcement Type</label>
                                </div>
                                <div className="col-sm-6 d-flex">
                                    <div className="form-check form-check-inline w-100">
                                        <input
                                            className="form-check-input"
                                            onChange={handleInput}
                                            type="radio"
                                            name="type"
                                            checked={announcementType.toString() === '1'} value="1"
                                        />Info - Green
                                    </div>
                                    <div className="form-check form-check-inline w-100">
                                        <input
                                            className="form-check-input"
                                            onChange={handleInput}
                                            type="radio"
                                            name="type"
                                            checked={announcementType.toString() === '2'} value="2"
                                        />Warning - Red
                                    </div>
                                    <div className="form-check form-check-inline w-100">
                                        <input
                                            className="form-check-input"
                                            onChange={handleInput}
                                            type="radio"
                                            name="type"
                                            checked={announcementType.toString() === '3'} value="3"
                                        />Alert - Yellow
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <hr className="mt-5" />

                    <div className="row mt-5">
                        <div className="col-sm-12">
                            <div className="gw__form-footer mb-5">
                                <Link to={`/companies/${companyId}/announcements`}>
                                    <button type="button" className="btn btn-cancel">Cancel</button>
                                </Link>
                                <button type="button" className="btn btn-primary" disabled={false} onClick={handleSave}>
                                    {isEditing ? 'Update' : 'Save'}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
    );
};

export default AnnouncementForm;